import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from "chart.js"
import React from "react"
import { Bar } from "react-chartjs-2"

import timesheetService from "../services/timesheet.service"
import LoadingSpinner from "./LoadingSpinner"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      position: "right" as "right",
      labels: {
        font: {
          family: "Inter",
          size: 14,
          color: "#000",
        },
      },
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
  maintainAspectRatio: false,
};

export function TimesheetEntriesChart() {
  const [timesheets, setTimesheets] = React.useState(null);
  React.useEffect(() => {
    const fetchTimesheets = async () => {
      setTimesheets(await timesheetService.analytics());
    };

    fetchTimesheets();

    return () => {
      setTimesheets(null);
    };
  }, []);

  return (
    <>
      {timesheets && (
        <Bar options={options} data={timesheets} height={25} width={100} />
      )}
      {!timesheets && <LoadingSpinner />}
    </>
  );
}

import { HeadingLevel, Paragraph, TextRun } from "docx"

import sortByName from "../../../common/sorting"
import { Skill } from "../../../models/Skill"
import { groupByKey } from "../../../utils/groupBy"

export const makeSkills = (skills: Skill[], title: string) => {
  const items = [];

  if (skills.length > 0) {
    items.push(
      new Paragraph({
        text: title,
        heading: HeadingLevel.HEADING_4,
      })
    );

    const groupedSkills = groupByKey(skills, "rating", { omitKey: false });

    [5, 4, 3, 2, 1].map((key) => {
      items.push(
        new Paragraph({
          children: [
            new TextRun({
              text: Array(key).fill("★").join(""),
              bold: true,
              size: 30,
            }),
          ],
          spacing: {
            before: 100,
            after: 50,
          },
        })
      );

      if (groupedSkills[key]) {
        const sortedSkills = sortByName(groupedSkills[key], "name");

        sortedSkills.forEach((skill) => {
          items.push(
            new Paragraph({
              text: `${skill.name}`,
            })
          );
        });
      }
    });
  }

  return items;
};

import { BaseService } from "./base.service";
import { TimesheetApproval } from "../models/TimesheetApproval";

class TimesheetApprovalService extends BaseService<TimesheetApproval> {
  API_ROOT = "public-api";
  BASE_PATH = "/timesheet_approvals/";
  MODEL = TimesheetApproval;

  approve(approval: TimesheetApproval, secret: string) {
    return this.update(
      approval.id,
      {
        ...approval,
        approved_date: new Date(),
        rejected_date: null,
      },
      {
        secret,
      }
    );
  }

  reject(approval: TimesheetApproval, secret: string) {
    return this.update(
      approval.id,
      {
        ...approval,
        approved_date: null,
        rejected_date: new Date(),
      },
      {
        secret,
      }
    );
  }
}

export default new TimesheetApprovalService();

import { Link as RouterLink } from 'react-router-dom';

import { Typography } from '@mui/material';
import Link from '@mui/material/Link';

export default function TableLink(props) {
  const { to, title } = props;

  return (
    <Typography variant="h6" component="div">
      <Link to={to} underline="none" component={RouterLink}>
        {title}
        {props.children}
      </Link>
    </Typography>
  );
}

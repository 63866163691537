import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { DatePicker } from "@mui/lab"
import { FormHelperText, TextField } from "@mui/material"

import { FormInputProps } from "./FormInputProps"

export const FormInputDatePicker = ({
  field,
  control,
  register,
  errors,
}: FormInputProps) => {
  const { t } = useTranslation();
  const { name, label, required } = field;

  const fullLabel = `${label}${required ? " *" : ""}`;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <DatePicker
            {...register(name, { required: required })}
            fullWidth
            mask="__.__.____"
            label={fullLabel}
            value={value ? value : null}
            onChange={(val: any) => {
              onChange(val?.format("YYYY-MM-DD") || null);
            }}
            variant="outlined"
            renderInput={(params) => (
              <TextField required={required} {...params} error={!!error} />
            )}
            helperText={error ? error.message : null}
          />
          {errors[name] && (
            <FormHelperText error={true}>{t("Required")}</FormHelperText>
          )}
        </>
      )}
    />
  );
};

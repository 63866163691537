import { useContext } from "react"
import { Navigate, useOutlet } from "react-router-dom"

import { Box } from "@mui/material"

import SimpleBreadcrumbs from "../components/Breadcrumbs"
import NavBarMobile from "../components/NavBarMobile"
import { GlobalContext } from "../contexts/GlobalContext"
import { useLocalStorage } from "../hooks/useLocalStorage"

export const ProtectedLayout = () => {
  const [user] = useLocalStorage("user", null);
  const { contract, timesheet, currentApp } = useContext(GlobalContext) as any;

  const outlet = useOutlet();

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <NavBarMobile />
      {currentApp === "timesheets" && (
        <SimpleBreadcrumbs contract={contract} timesheet={timesheet} />
      )}
      <Box sx={{ padding: 2 }}>{outlet}</Box>
    </div>
  );
};

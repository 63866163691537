import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"

import { Delete, DeleteOutlined } from "@mui/icons-material"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem
} from "@mui/material"

import FormPopUp from "../components/FormPopUp"
import { GlobalContext } from "../contexts/GlobalContext"
import { BaseService } from "../services/base.service"
import DeleteIcon from "./DeleteIcon"
import EditIcon from "./EditIcon"

export interface ActionsDropdownProps {
  popUpTitle: string;
  instance: any;
  deletable: boolean;
  editable: boolean;
  baseUrl: string;
  ignoredFields: string[];
  instanceList: any[];
  archivable?: boolean;
  extraParams?: { [key: string]: any };
  fullScreen?: boolean;
  setInstanceList: (instanceList: any[]) => void;
}

export default function ActionsDropdown(props: ActionsDropdownProps) {
  const { t } = useTranslation();
  const {
    popUpTitle,
    deletable,
    editable,
    instance,
    instanceList,
    archivable,
    extraParams,
    fullScreen,
    baseUrl,
    ignoredFields,
    setInstanceList,
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const { setBackdrop } = useContext(GlobalContext) as any;

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    if (confirm) {
      setOpen(true);
    } else {
      onDelete();
    }
  };

  const onDelete = async () => {
    return await new BaseService()
      .deleteFromUrl(baseUrl, instance.id)
      .then((type: any) => {
        setInstanceList(
          instanceList.filter((item: any) => item.id !== instance.id) || []
        );
      });
  };

  const onArchive = async (type: string) => {
    setBackdrop(true);
    if (type === "archived") {
      await new BaseService().archiveFromUrl(baseUrl, instance.id);

      setInstanceList(
        instanceList.filter((item: any) => item.id !== instance.id)
      );
    } else if (type === "unarchived") {
      await new BaseService().unarchiveFromUrl(baseUrl, instance.id);
      setInstanceList(
        instanceList.filter((item: any) => item.id !== instance.id)
      );
    }

    setBackdrop(false);
  };

  return (
    <div>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {editable && (
          <FormPopUp
            baseUrl={baseUrl}
            buttonType={"menu-item"}
            extraParams={extraParams}
            fullScreen={fullScreen}
            icon={<EditIcon text={true} />}
            ignoredFields={ignoredFields}
            handleCloseMenu={handleCloseMenu}
            instance={instance}
            instanceList={instanceList}
            setInstanceList={setInstanceList}
            title={popUpTitle}
          />
        )}

        {(deletable || archivable) && <Divider />}

        {archivable && (
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              onArchive(instance.archived ? "unarchived" : "archived");
            }}
          >
            <DeleteOutlined color="primary" sx={{ mr: 1 }} />{" "}
            {instance.archived ? t("Unarchive") : t("Archive")}
          </MenuItem>
        )}

        {deletable && (
          <div>
            <MenuItem
              onClick={() => {
                handleCloseMenu();
                handleClickOpen();
              }}
            >
              <DeleteIcon text={true} />
            </MenuItem>

            <Dialog onClose={handleClose} open={open}>
              <DialogTitle>
                {t("Are you sure you want to delete this entry?")}
              </DialogTitle>
              {archivable && (
                <DialogContent>
                  <DialogContentText>
                    {t("Alternatively, you can archive it instead.")}
                  </DialogContentText>
                </DialogContent>
              )}
              <DialogActions>
                <Button onClick={handleClose}>{t("Cancel")}</Button>
                <Button color="error" onClick={onDelete} variant="outlined">
                  <Delete sx={{ mr: 1 }} /> {t("Delete")}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </Menu>
    </div>
  );
}

import { Paper } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import moment from "moment";
import { useTranslation } from "react-i18next";

export default function TimesheetPreviewTable(props) {
  const { timesheet } = props;

  const { t } = useTranslation();

  const columns = [
    {
      field: "date",
      headerName: t("Date"),
      width: 150,
      renderCell: (params) => moment(params.value).format("L dd"),
    },
    {
      field: "total",
      headerName: t("Hours"),
      width: 75,
    },
    {
      field: "comment",
      headerName: t("Comment"),
      flex: 1,
    },
  ];

  return (
    <Paper>
      <DataGridPro
        rows={timesheet.timesheet_entries}
        columns={columns}
        pageSize={35}
        disableSelectionOnClick
        autoHeight
        density={"compact"}
        hideFooter
      />
    </Paper>
  );
}

import axios from "axios";

import { alertService } from "./alert.service";
import authService from "./auth.service";

const getCurrentUser = () => {
  const user = localStorage.getItem("user");
  if (user) {
    return JSON.parse(user);
  }
  return undefined;
};

const config: any = {
  headers: {
    common: {
      "Content-Type": "application/json",
    },
  },
};

let api = axios.create(config);

api.interceptors.request.use(
  (config: any) => {
    const currentUser = getCurrentUser();
    // Do something before request is sent
    if (currentUser) {
      config.headers.common["Authorization"] = `Bearer ${
        currentUser ? currentUser.access_token : ""
      }`;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response: any) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 400) {
        const messages = [];

        Object.keys(error.response.data).forEach((key: string) => {
          messages.push(`${key}: ${error.response.data[key]}`);
        });

        alertService.error(messages, {
          autoHideDuration: 3000,
        });
      } else if (error.response.status === 403) {
        authService.logout();
      }
    } else {
      alertService.error(error.toString(), {});
    }

    return Promise.reject(error);
  }
);

export default api;

import { Navigate } from 'react-router-dom';

import { useLocalStorage } from '../hooks/useLocalStorage';

export const NoUserRoute = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);

  if (user) {
    // user is not authenticated
    return <Navigate to="/timesheets" />;
  }
  return children;
};

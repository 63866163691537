import { makeTime } from "../common/dateTime"

export class TimesheetEntry {
  [key: string]: any;

  init(entry: any) {
    Object.assign(this, {
      ...entry,
      date: makeTime(entry.date),
      modified: makeTime(entry.modified),
      created: makeTime(entry.created),
      start_1: makeTime(entry.start_1),
      start_2: makeTime(entry.start_2),
      end_1: makeTime(entry.end_1),
      end_2: makeTime(entry.end_2),
    });

    return this;
  }

  initBulk(entries: any[]) {
    const result: any[] = [];

    entries.forEach((entry: any) => {
      result.push(new TimesheetEntry().init(entry));
    });

    return result;
  }
}

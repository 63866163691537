import { useTranslation } from 'react-i18next';

import { Delete } from '@mui/icons-material';
import { Typography } from '@mui/material';

export default function DeleteIcon(props: any) {
  const { t } = useTranslation();

  return (
    <>
      <Delete color="error" sx={{ mr: 1 }} />
      {props.text && <Typography color="error">{t("Delete")}</Typography>}
    </>
  );
}

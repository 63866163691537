import appSettings from "../common/apiSettings"
import { FormField } from "../components/form-components/FormInputProps"
import { PaginatedResponse } from "../models/PaginatedResponse"
import API from "./api"

export const getCurrentLang = () => {
  return localStorage.getItem("i18nextLng") || "en";
};

const extractFields = (response: any, ignoredFields: string[]) => {
  const meta = response.data.actions.POST;
  const fields = [];
  Object.keys(meta).forEach((key) => {
    if (!ignoredFields.includes(key)) {
      fields.push({ name: key, ...meta[key] });
    }
  });

  return fields;
};
export class BaseService<T> {
  API_DOMAIN: string = appSettings.api.root;
  API_LANG = getCurrentLang().toLowerCase();
  API_ROOT = "api";
  API_VERSION = "v0";

  BASE_PATH: string;
  MODEL: any;

  cache = {};

  get API_V0() {
    return `${this.API_DOMAIN}/${this.API_LANG}/${this.API_ROOT}/${this.API_VERSION}`;
  }

  async getFormFields(ignoredFields: string[] = []): Promise<FormField[]> {
    if (!this.API_V0) {
      throw new Error("API_V0 is not defined");
    }

    if (!this.BASE_PATH) {
      throw new Error("BASE_PATH is not defined");
    }

    const url = `${this.API_V0}${this.BASE_PATH}`;

    if (this.cache[url]) {
      return this.cache[url];
    }

    ignoredFields.push("archived");

    return await API.options(url).then((response) => {
      const fields = extractFields(response, ignoredFields);
      this.cache[url] = fields;
      return fields;
    });
  }

  async detail(id: any, params?: {}): Promise<T> {
    return await API.get(`${this.API_V0}${this.BASE_PATH}${id}/`, {
      params,
    }).then((response) =>
      this.MODEL ? new this.MODEL().init(response.data) : (response.data as T)
    );
  }

  async get(params?: {}): Promise<T[]> {
    return await API.get(`${this.API_V0}${this.BASE_PATH}`, { params }).then(
      (response) =>
        this.MODEL ? new this.MODEL().init(response.data) : (response.data as T)
    );
  }

  async list(params?: {}): Promise<PaginatedResponse<T>> {
    return await API.get(`${this.API_V0}${this.BASE_PATH}`, { params }).then(
      (response) => {
        if (this.MODEL) {
          const results = new this.MODEL().initBulk(response.data.results);
          return { ...response.data, results };
        }

        return response.data as PaginatedResponse<T>;
      }
    );
  }

  async getFromUrl<T>(path: string, params?: {}): Promise<T[]> {
    return await API.get(`${this.API_V0}${path}`, {
      params,
    }).then((response) =>
      this.MODEL
        ? new this.MODEL().initBulk(response.data.results)
        : (response.data as T)
    );
  }

  async getFormMeta(path: string, ignoredFields: string[] = []) {
    const defaultIgnoredFields = ["archived"];
    const url = `${this.API_V0}${path}`;

    if (this.cache[url]) {
      return this.cache[url];
    }

    return await API.options(url).then((response) => {
      const fields = extractFields(response, [
        ...defaultIgnoredFields,
        ...ignoredFields,
      ]);
      this.cache[url] = fields;
      return fields;
    });
  }

  async createFromUrl(path: string, data: any): Promise<T> {
    return await API.post(`${this.API_V0}${path}`, data).then(
      (response) => response.data
    );
  }

  async update(id: any, data: any, params?: {}): Promise<T> {
    return await API.put(`${this.API_V0}${this.BASE_PATH}${id}/`, data, {
      params,
    }).then((response) =>
      this.MODEL ? new this.MODEL().init(response.data) : (response.data as T)
    );
  }

  async updateByUrl(path: string, id: any, data: any, params?: {}): Promise<T> {
    return await API.put(`${this.API_V0}${path}${id}/`, data, {
      params,
    }).then((response) =>
      this.MODEL ? new this.MODEL().init(response.data) : (response.data as T)
    );
  }

  async delete(id: any): Promise<boolean> {
    return await API.delete(`${this.API_V0}${this.BASE_PATH}${id}/`);
  }

  async deleteFromUrl(path: string, id: any): Promise<boolean> {
    return await API.delete(`${this.API_V0}${path}${id}/`);
  }
  async archiveFromUrl(path: string, id: any): Promise<string> {
    return await API.post(`${this.API_V0}${path}${id}/archive/`);
  }
  async unarchiveFromUrl(path: string, id: any): Promise<string> {
    return await API.post(`${this.API_V0}${path}${id}/unarchive/`);
  }
}

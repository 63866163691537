import { ExternalHyperlink, HeadingLevel, Paragraph, TextRun } from "docx"

import sortByName from "../../../common/sorting"
import { ExternalProfile } from "../../../models/ExternalProfile"

export const makeExternalProfiles = (
  externalProfiles: ExternalProfile[],
  title: string
) => {
  const items = [];

  if (externalProfiles.length > 0) {
    items.push(
      new Paragraph({
        text: title,
        heading: HeadingLevel.HEADING_4,
      })
    );

    const sortedExternalProfiles = sortByName(externalProfiles, "source");

    for (const item of sortedExternalProfiles) {
      items.push(
        new Paragraph({
          children: [
            new ExternalHyperlink({
              children: [
                new TextRun({
                  text: item.source,
                  style: "Hyperlink",
                }),
              ],
              link: item.url,
            }),
          ],
        })
      );
    }
  }

  return items;
};

import { useState } from "react"
import { useTranslation } from "react-i18next"

import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { LoadingButton } from "@mui/lab"

import appSettings from "../../common/apiSettings"

export default function CheckoutFormPage() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    let bearerToken: any = {};

    const user = localStorage.getItem("user");

    if (user) {
      bearerToken = JSON.parse(user);
    }

    // Use fetch to send the token ID and any other payment data to your server.
    // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    const response = await fetch(
      `${appSettings.api.root}/api/v0/subscriptions/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearerToken.access_token}`,
        },
      }
    );

    // Return and display the result of the charge.
    response.json().then((data) => {
      window.location = data.url;
      setLoading(false);
    });
  };

  return (
    <>
      <p>
        {t(
          "Lancer Express is free to use. However, if you want to support us, you can subscribe to our service."
        )}
      </p>
      <form onSubmit={handleSubmit}>
        <LoadingButton type="submit" variant="contained" loading={loading}>
          {t("Payment method")} <OpenInNewIcon sx={{ ml: 1 }} />
        </LoadingButton>
      </form>
    </>
  );
}

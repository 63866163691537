import { HeadingLevel, Paragraph, TextRun } from "docx"

import { makeTime } from "../../../common/dateTime"
import { PortfolioItem } from "../../../models/PortfolioItem"

const htmlToWord = (html: string) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  const items = [];

  [...doc.body.childNodes].map((node: any) => {
    if (node.nodeName === "UL") {
      items.push(
        new Paragraph({
          children: [], // Just newline without text
        })
      );
      node.childNodes.forEach((li: any) => {
        items.push(
          new Paragraph({
            text: li.innerText,
            bullet: {
              level: 0,
            },
          })
        );
      });
      items.push(
        new Paragraph({
          children: [], // Just newline without text
        })
      );
    } else if (node.nodeName === "P") {
      items.push(
        new Paragraph({
          text: node.innerText,
          spacing: {
            after: 50,
            before: 50,
          },
        })
      );
    }
  });

  return items;
};

export const makePortfolioItems = (
  portfolioItems: PortfolioItem[],
  title: string,
  todayString: string
) => {
  const items = [];

  if (portfolioItems.length > 0) {
    items.push(
      new Paragraph({
        text: title,
        heading: HeadingLevel.HEADING_3,
      })
    );

    for (const item of portfolioItems) {
      const description = htmlToWord(item.description);
      let endDate = todayString;

      const startDate = makeTime(
        `${item.start_date_year}-${item.start_date_month}`
      ).format("MMMM YYYY");

      if (!item.currently_works_here) {
        endDate = makeTime(
          `${item.end_date_year}-${item.end_date_month}`
        ).format("MMMM YYYY");
      }

      items.push(
        new Paragraph({
          text: `${item.company ? `${item.company}, ` : ""}${item.title}`,
          heading: HeadingLevel.HEADING_4,
        }),
        new Paragraph({
          text: `${startDate} - ${endDate}${
            item.location ? `, ${item.location}` : ""
          }`,
          spacing: {
            after: 100,
          },
        }),
        ...description
      );
    }
  }

  return items;
};

import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Link as RouterLink, useNavigate } from "react-router-dom"

import { LoadingButton } from "@mui/lab"
import { Link } from "@mui/material"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

import Copyright from "../components/Copyright"
import { useLocalStorage } from "../hooks/useLocalStorage"
import logo from "../logoDark.png"
import { JWTUser } from "../models/User"
import AuthService from "../services/auth.service"

function Login() {
  const { t } = useTranslation();
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const creds = {
      email: data.get("email"),
      password: data.get("password"),
    };
    AuthService.login(creds.email, creds.password)
      .then((user: JWTUser) => {
        setUser(user);
        navigate(0);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(https://source.unsplash.com/random)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              id="navbar-logo"
              src={logo}
              height="100%"
              width="100%"
              style={{ maxWidth: 200, maxHeight: 200 }}
              alt="Lancer Express"
            />
            <Typography
              component="h1"
              variant="h6"
              sx={{ color: "primary.main" }}
            >
              {t("The digital Swiss Army Knife for Freelancers")}
            </Typography>
            <Typography component="h2" variant="h4" sx={{ paddingTop: 8 }}>
              {t("Sign In")}
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label={t("Email Address")}
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={t("Password")}
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                loading={loading}
                size="large"
              >
                {t("Sign In")}
              </LoadingButton>
              <Grid container>
                <Grid item xs>
                  <Typography component="div">
                    <Link
                      to="/reset-password"
                      underline="none"
                      component={RouterLink}
                    >
                      {t("Forgot password?")}
                    </Link>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography component="div">
                    <Link
                      to="/register"
                      underline="none"
                      component={RouterLink}
                    >
                      {t("Don't have an account? Sign Up")}
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 8 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Login;

import { useTranslation } from 'react-i18next';

import { EditOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';

export default function EditIcon(props: any) {
  const { t } = useTranslation();

  return (
    <>
      <EditOutlined sx={{ mr: 1 }} />
      {props.text && <Typography>{t("Edit")}</Typography>}
    </>
  );
}

import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { Circle, CircleOutlined } from "@mui/icons-material"
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  List,
  ListItemText,
  Rating
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { Box } from "@mui/system"

import { VitycurryContext } from "../contexts/VitycurryContext"
import { Skill } from "../models/Skill"
import skillsService from "../services/skills.service"
import { groupByKey } from "../utils/groupBy"
import AddIcon from "./AddIcon"
import EmptyListItem from "./EmptyListItem"
import FormPopUp from "./FormPopUp"
import LoadingSpinner from "./LoadingSpinner"

const StyledRating = styled(Rating)(({ theme }) => {
  return {
    "& .MuiRating-iconFilled": {
      color: theme.palette.text.primary,
    },
  };
});

export default function Skills() {
  const { skills, setSkills } = useContext(VitycurryContext);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const formDefault: Skill = {
    name: "",
    rating: 1,
  };
  const ignoredFields = ["id", "created", "modified", "profile"];

  const handleClick = (id: any) => {
    console.info("You clicked the Chip.", id);
  };

  const handleDelete = async (id: any) => {
    setLoading(true);
    await skillsService.delete(id);
    setSkills(skills.filter((skill) => skill.id !== id));
    setLoading(false);
  };

  useEffect(() => {
    async function fetchItems() {
      const response = await skillsService.list();
      setSkills(response.results);
    }

    fetchItems();
    return;
  }, []);

  if (skills == null) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Card variant="outlined">
        <CardHeader
          title={t("Skills")}
          sx={{ paddingBottom: 0 }}
          action={
            <FormPopUp
              title={"Add skill"}
              btnText={"Add"}
              buttonType={"card-action"}
              initialValues={formDefault}
              setInstanceList={setSkills}
              instanceList={skills}
              icon={<AddIcon />}
              baseUrl={skillsService.BASE_PATH}
              ignoredFields={ignoredFields}
            />
          }
        />
        <CardContent>
          <List disablePadding>
            {[5, 4, 3, 2, 1].map((key: number) => {
              const orderItems = groupByKey(skills, "rating", {
                omitKey: false,
              })[key];

              return (
                <Box key={key} sx={{ mb: 4, p: 0, textAlign: "left" }}>
                  <ListItemText>
                    <StyledRating
                      readOnly
                      defaultValue={key}
                      precision={1}
                      icon={<Circle fontSize="inherit" />}
                      emptyIcon={<CircleOutlined fontSize="inherit" />}
                      color="primary"
                    />
                  </ListItemText>

                  <ListItemText>
                    {orderItems?.map((skill: any) => {
                      return (
                        <Chip
                          sx={{ mr: 1, mb: 1, backgroundColor: "#ffffff" }}
                          label={skill.name}
                          variant="outlined"
                          onClick={() => handleClick(skill.id)}
                          onDelete={() => handleDelete(skill.id)}
                          disabled={loading}
                          key={skill.id}
                        />
                      );
                    }) || <EmptyListItem />}
                  </ListItemText>
                </Box>
              );
            })}
          </List>
        </CardContent>
      </Card>
    </>
  );
}

import { useContext, useEffect } from "react"
import { Route, Routes } from "react-router-dom"

import { ProtectedRoute } from "../components/ProtectedRoute"
import { GlobalContext } from "../contexts/GlobalContext"
import OpportunitiesContextProvider from "../contexts/OpportunitiesContext"
import ContactsList from "../pages/OpportunitiesList"

export default function OpportunitiesApp() {
  const { setCurrentApp } = useContext(GlobalContext);

  useEffect(() => {
    setCurrentApp("opportunities");

    return;
  }, []);

  return (
    <>
      <OpportunitiesContextProvider
        children={
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <ContactsList />
                </ProtectedRoute>
              }
            />
          </Routes>
        }
      />
    </>
  );
}

import i18next from "i18next"
import moment from "moment"
import { useContext } from "react"
import { useTranslation } from "react-i18next"

import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select
} from "@mui/material"

import { GlobalContext } from "../contexts/GlobalContext"
import LanguageSelect from "./LanguageSelect"

export default function SettingsForm() {
  const { t } = useTranslation();
  const { settings, setSettings } = useContext(GlobalContext) as any;

  return (
    <FormGroup>
      <LanguageSelect />
      <FormControl fullWidth>
        <InputLabel id="locale-select-label">{t("Date Format")}</InputLabel>
        <Select
          labelId="locale-select-label"
          id="locale-select"
          value={settings.timesheets.momentLocale || ""}
          label={t("Date Format")}
          onChange={(e: any) => {
            const value = {
              ...settings,
              timesheets: {
                ...settings.timesheets,
                momentLocale: e.target.value,
              },
            };

            setSettings(value);
          }}
        >
          {moment.locales().map((locale: string) => (
            <MenuItem value={locale} key={locale}>
              {locale}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          {t("Select the locale to use for date formatting.")}
        </FormHelperText>
      </FormControl>
      <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label">
          {t("Time Format")}
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={(e: any) => {
            const value = e.target.value === "true" ? true : false;
            setSettings({
              ...settings,
              timesheets: {
                ...settings.timesheets,
                ampm: value,
              },
            });
          }}
          value={settings.timesheets.ampm}
        >
          <FormControlLabel
            value={true}
            control={<Radio />}
            label={t("12h (e.g., 7:15 pm)")}
          />
          <FormControlLabel
            value={false}
            control={<Radio />}
            label={t("24h (e.g., 19:15)")}
          />
        </RadioGroup>
      </FormControl>
    </FormGroup>
  );
}

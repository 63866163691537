import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemText
} from "@mui/material"

import { makeTime } from "../common/dateTime"
import { VitycurryContext } from "../contexts/VitycurryContext"
import { Profile } from "../models/Profile"
import profileService from "../services/profile.service"
import ActionsDropdown from "./ActionsDropdown"
import LoadingSpinner from "./LoadingSpinner"

export default function ProfileDisplay() {
  const { t } = useTranslation();
  const { profiles, setProfiles } = useContext(VitycurryContext) as any;
  const [formFields, setFormFields] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfile = async () => {
      const profiles = await profileService.list();
      setProfiles([profiles.results[0]]);
      setFormFields(
        await profileService.getFormFields([
          "id",
          "created",
          "modified",
          "user",
        ])
      );
    };

    fetchProfile();

    setLoading(false);
    return;
  }, []);

  if (profiles == null) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {loading && <LoadingSpinner />}

      {profiles &&
        profiles.map((profile: Profile) => (
          <Card
            variant="outlined"
            key={profile.id}
            sx={{
              padding: 0,
            }}
          >
            <CardHeader
              title={t("About me")}
              sx={{ paddingBottom: 0 }}
              action={
                <ActionsDropdown
                  popUpTitle={t("Edit")}
                  instance={profile}
                  instanceList={profiles}
                  setInstanceList={setProfiles}
                  editable={true}
                  deletable={false}
                  baseUrl={profileService.BASE_PATH}
                  ignoredFields={["id", "created", "modified", "user"]}
                />
              }
            />

            <CardContent sx={{ padding: 0 }}>
              <List key={profile.id} disablePadding>
                <ListItem alignItems="center">
                  <Avatar
                    alt="Remy Sharp"
                    src={profile.user_photo ? profile.user_photo : ""}
                    sx={{ width: "100%", height: "100%" }}
                    variant="rounded"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={t(profile.salutation) || "-"}
                    secondary={t("Salutation")}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={profile.first_name || "-"}
                    secondary={t("First Name")}
                  />
                  <ListItemText
                    primary={profile.last_name || "-"}
                    secondary={t("Last Name")}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={profile.title || "-"}
                    secondary={t("Title")}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={profile.contact_email || "-"}
                    secondary={t("Contact E-Mail")}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={profile.website || "-"}
                    secondary={t("Website")}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={profile.phone_number || "-"}
                    secondary={t("Phone Number")}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={t(profile.residence_address) || "-"}
                    secondary={t("Residence Address")}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={profile.academic_title || "-"}
                    secondary={t("Academic Title")}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      profile.date_of_birth
                        ? `${makeTime(profile.date_of_birth).format("L")}`
                        : "-"
                    }
                    secondary={t("Date of birth")}
                  />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        ))}
    </>
  );
}

import {
  ChangeEvent,
  MouseEvent,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react"
import { useTranslation } from "react-i18next"

import DeleteIcon from "@mui/icons-material/Delete"
import {
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField
} from "@mui/material"
import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import IconButton from "@mui/material/IconButton"
import * as locales from "@mui/material/locale"
import Paper from "@mui/material/Paper"
import {
  alpha,
  createTheme,
  ThemeProvider,
  useTheme
} from "@mui/material/styles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Toolbar from "@mui/material/Toolbar"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import { visuallyHidden } from "@mui/utils"

import FormPopUp from "../components/FormPopUp"
import NoResults from "../components/NoResults"
import { GlobalContext } from "../contexts/GlobalContext"
import ActionsDropdown from "./ActionsDropdown"
import AddIcon from "./AddIcon"

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
export interface HeadCell {
  disablePadding: boolean;
  id: any;
  label: string;
  numeric: boolean;
  width?: number | string;
  render?: (row: any, headCell: HeadCell) => ReactNode;
}

export default function EnhancedTable(props) {
  const {
    headCells,
    service,
    title,
    orderByDefault,
    searchable,
    ignoredFields,
    initialValues,
    archivable = true,
    editable = true,
    extraParams = {},
  } = props;
  const { t, i18n } = useTranslation();
  const { setBackdrop } = useContext(GlobalContext) as any;
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState(orderByDefault || "created");
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [count, setCount] = useState(0);
  const defaultParams = {
    archived: false,
    limit: 10,
    offset: 0,
    search: "",
  };

  const [queryParams, setQueryParams] = useState({
    ...defaultParams,
    ...extraParams,
  }) as any;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [loadedPages, setLoadedPages] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(queryParams.limit);
  const [initDone, setInitDone] = useState(false);

  const muiLang = {
    en: "enUS",
    de: "deDE",
  }[i18n.language];
  const [locale] = useState(muiLang);

  const theme = useTheme();
  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme]
  );

  const rowsPerPageOptions = [10, 25, 50, 100];

  const getItems = async () => {
    setBackdrop(true);
    const items = (await service.list(queryParams)) as any;
    // TODO: check if page is already loaded, use setRows(items.results);
    setRows([...rows, ...items.results]);
    setCount(items.count);
    setBackdrop(false);
    setInitDone(true);
  };

  if (
    headCells &&
    headCells.length > 0 &&
    headCells[headCells.length - 1].id !== "row_actions"
  ) {
    headCells.push({
      id: "row_actions",
      numeric: false,
      label: "",
      render: (row: any, headCell: any) => {
        return (
          <ActionsDropdown
            popUpTitle={t("Edit")}
            instance={row}
            instanceList={rows}
            setInstanceList={setRows}
            deletable={true}
            archivable={archivable}
            editable={editable}
            fullScreen={true}
            baseUrl={service.BASE_PATH}
            ignoredFields={ignoredFields}
          />
        );
      },
    });
  }

  useEffect(() => {
    getItems();

    return () => setInitDone(false);
  }, [queryParams]);

  const onDelete = () => {
    selected.forEach(async (id: string) => await service.delete(id));
    setSelected([]);
    setRows(rows.filter((row: any) => !selected.includes(row.id)));
  };

  const createSortHandler = (property: any) => (event: MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event: MouseEvent<unknown>, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    if (newPage > page && !loadedPages.includes(newPage)) {
      setQueryParams({ ...queryParams, offset: newPage * rowsPerPage });
      setLoadedPages([...loadedPages, newPage]);
    }

    setPage(newPage);
  };

  const resetTable = (resetQueryParams = false) => {
    setLoadedPages([]);
    setRows([]);
    setPage(0);
    setInitDone(false);

    if (resetQueryParams) {
      setQueryParams({
        ...defaultParams,
        ...extraParams,
      });
    }
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(event.target.value);
    resetTable();
    setQueryParams({
      ...queryParams,
      offset: 0,
      limit: parseInt(event.target.value),
    });
  };

  const handleCreate = async (item: any) => {
    resetTable(true);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <ThemeProvider theme={themeWithLocale}>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          {(selected.length > 0 || title) && (
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(selected.length > 0 && {
                  bgcolor: (theme) =>
                    alpha(
                      theme.palette.primary.main,
                      theme.palette.action.activatedOpacity
                    ),
                }),
              }}
            >
              {selected.length > 0 ? (
                <Typography
                  sx={{ flex: "1 1 100%" }}
                  color="inherit"
                  variant="subtitle1"
                  component="div"
                >
                  {selected.length} {t("selected")}
                </Typography>
              ) : (
                <Typography
                  sx={{ flex: "1 1 100%" }}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  {title} {initDone && <>({count})</>}
                </Typography>
              )}

              {archivable && selected.length == 0 && (
                <FormGroup>
                  <FormControlLabel
                    value={queryParams.archived}
                    control={<Switch />}
                    label={t("Archived")}
                    labelPlacement="end"
                    onChange={async (event: any) => {
                      setRows([]);
                      setQueryParams({
                        ...queryParams,
                        archived: event.target.checked,
                      });
                    }}
                  />
                </FormGroup>
              )}

              {selected.length > 0 && (
                <Tooltip title={t("Delete")}>
                  <IconButton color="error" onClick={onDelete}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
              <FormPopUp
                baseUrl={service.BASE_PATH}
                buttonType={"card-action"}
                icon={<AddIcon />}
                ignoredFields={ignoredFields}
                initialValues={initialValues}
                instanceList={rows}
                customSetInstanceList={handleCreate}
                title={t("Add")}
                extraParams={extraParams}
              />
            </Toolbar>
          )}

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            spacing={2}
          >
            {searchable && (
              <Toolbar
                sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                }}
              >
                <TextField
                  id="search"
                  label={t("Search")}
                  type="search"
                  variant="standard"
                  onKeyPress={async (event: any) => {
                    if (event.key === "Enter") {
                      setRows([]);
                      setLoadedPages([]);
                      setPage(0);
                      setQueryParams({
                        ...queryParams,
                        offset: 0,
                        search: event.target.value,
                      });
                    }
                  }}
                />
              </Toolbar>
            )}
            {initDone && count > rowsPerPageOptions[0] && (
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={count || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Stack>

          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={
                        selected.length > 0 && selected.length < rows.length
                      }
                      checked={
                        rows.length > 0 && selected.length === rows.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  </TableCell>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? "right" : "left"}
                      padding={headCell.disablePadding ? "none" : "normal"}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
                {rows &&
                  rows.length > 0 &&
                  stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id.toString());
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          key={row.id}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          selected={isItemSelected}
                          hover
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                              onClick={(event) =>
                                handleClick(event, row.id.toString())
                              }
                            />
                          </TableCell>

                          {headCells.map((headCell: HeadCell) => (
                            <TableCell
                              id={labelId}
                              key={headCell.id}
                              width={headCell.width}
                            >
                              {headCell.render
                                ? headCell.render(row, headCell)
                                : row[headCell.id]}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                {rows.length === 0 && initDone && (
                  <>
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={headCells.length + 1}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <NoResults />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </ThemeProvider>
  );
}

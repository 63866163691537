import React, { useState } from "react";
import { Navigate } from "react-router-dom";

import AuthService from "../services/auth.service";
import useSettings from "../hooks/useSettings";
import { useLocalStorage } from "../hooks/useLocalStorage";

export const GlobalContext: any = React.createContext({});

const GlobalContextProvider = ({ children }: any) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [momentLocale, setMomentLocale] = useState("de");
  const [settings, setSettings] = useSettings();
  const [currentApp, setCurrentApp] = useState(null);
  const [contacts, setContacts] = useState(null);
  const [backdrop, setBackdrop] = useState(false);

  const logOut = (callback?: any) => {
    AuthService.logout();
    setUser(null);

    if (callback) {
      callback();
    }

    return <Navigate to="/login" />;
  };

  return (
    <GlobalContext.Provider
      value={{
        user,
        setUser,
        logOut,
        momentLocale,
        setMomentLocale,
        settings,
        setSettings,
        currentApp,
        setCurrentApp,
        contacts,
        setContacts,
        backdrop,
        setBackdrop,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
export default GlobalContextProvider;

import { Typography } from "@mui/material";
import Link from "@mui/material/Link";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      Made with{" "}
      <Typography component="span" sx={{ color: "error.main" }}>
        ❤
      </Typography>{" "}
      by{" "}
      <Link color="inherit" href="https://mathison.ch/">
        Mathison AG
      </Link>{" "}
      in Zurich, Switzerland
      <br />
      <br />
      {"© "} Mathison AG {new Date().getFullYear()}
    </Typography>
  );
}

export default Copyright;

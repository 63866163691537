import React, { useEffect, useState } from "react"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"

import {
  Autocomplete,
  CircularProgress,
  createFilterOptions,
  Dialog,
  FormHelperText,
  IconButton,
  TextField
} from "@mui/material"

import { BaseService } from "../../services/base.service"
import AddIcon from "../AddIcon"
import ReactiveForm from "../ReactiveForm"
import { FormInputProps } from "./FormInputProps"

const filter = createFilterOptions();

export const FormInputRelatedField: React.FC<FormInputProps> = ({
  field,
  control,
  register,
  errors,
  initialValues,
  setValue,
}: any) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, dialogToggleOpen] = React.useState(false);
  const [initialValue, setInitialValue] = React.useState([]);

  const { name, label, required, urlNamespace } = field;
  const fullLabel = `${label}${required ? " *" : ""}`;
  const isM2M = field.type === "m2m";

  useEffect(() => {
    const relatedFieldName = `${name}_obj${isM2M ? "s" : ""}`;
    if (initialValues && initialValues[relatedFieldName]) {
      if (isM2M) {
        setInitialValue(initialValues[relatedFieldName]);
        setOptions(initialValues[relatedFieldName]);
      } else {
        setInitialValue([initialValues[relatedFieldName]]);
        setOptions([initialValues[relatedFieldName]]);
      }
    } else {
      if (isM2M) {
        setValue(name, []);
      } else {
        setValue(name, null);
      }
    }
  }, []);

  const handleAfterSubmit = (
    item: any,
    onChange: (value: any) => void,
    value?: any
  ) => {
    let newOptions: any[] = [];
    let newValues: any[] | any = [];
    let newInitial: any[] = [];

    if (isM2M) {
      newOptions = [...options, item];
      newValues = [...value, item.id];
      newInitial = [...initialValue, item];
    } else {
      newOptions = [item];
      newValues = item.id;
      newInitial = [item];
    }

    setOptions(newOptions);
    onChange(newValues);
    setInitialValue(newInitial);
  };

  const getDefaultOptions = (value: any) => {
    let defaultOptions: any;

    if (isM2M) {
      defaultOptions =
        options?.filter((option: any) => value?.indexOf(option.id) > -1) || [];
    } else {
      defaultOptions =
        options?.find((option: any) => option.id === value) || null;
    }

    return defaultOptions;
  };

  const handleAutocompleteOnChange = (
    newValue: any,
    onChange: (value: any) => void
  ) => {
    if (isM2M) {
      onChange(newValue.map((x: any) => x.id));
    } else {
      onChange(newValue?.id || newValue?.inputValue || null);
    }
  };

  const fetchOptions = async (search?: string | any[]) => {
    setLoading(true);

    let params = {};
    if (!search || search === null || search.length === 0) {
      params = { limit: 5 };
    } else if (typeof search === "string") {
      params = { search };
    } else if (Array.isArray(search) && search.length > 0) {
      params = { id__in: search.join(",") };
    }

    const items: any = await new BaseService().getFromUrl(urlNamespace, params);

    setOptions(
      [
        ...initialValue,
        ...items.results.filter(
          (item) => !initialValue.map((item) => item.id).includes(item.id)
        ),
      ].sort((a, b) => a.name - b.name)
    );

    setLoading(false);
  };

  const handleClose = () => {
    dialogToggleOpen(false);
  };

  return (
    <React.Fragment>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          const defaultOption = getDefaultOptions(value);

          const afterSubmit = async (item: any) => {
            handleAfterSubmit(item, onChange, value);
            handleClose();
          };

          return (
            <>
              <Autocomplete
                {...register(name, { required: required })}
                id={name}
                name={name}
                open={open}
                multiple={isM2M}
                value={defaultOption}
                options={options || []}
                onChange={(event: any, newValue: any) =>
                  handleAutocompleteOnChange(newValue, onChange)
                }
                selectOnFocus
                freeSolo
                onOpen={() => {
                  fetchOptions(value);
                  setOpen(true);
                }}
                getOptionDisabled={(option: any) => option.disabled}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  if (
                    params.inputValue !== "" &&
                    !options
                      .map((option: any) => option.name)
                      .includes(params.inputValue)
                  ) {
                    filtered.push({
                      name: `No results for "${params.inputValue}"`,
                      disabled: true,
                    });
                  }

                  return filtered;
                }}
                renderOption={(props: any, option: any) => {
                  return (
                    <li
                      {...props}
                      key={option.id || option.name || option.title}
                    >
                      {option.name || option.title}
                    </li>
                  );
                }}
                onClose={() => {
                  setOpen(false);
                }}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                getOptionLabel={(option: any) =>
                  option.title ? option.title : option.name
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={fullLabel}
                    error={!!error}
                    sx={{ bgcolor: "white" }}
                    placeholder={t("Search") + "..."}
                    onChange={async (event: any) => {
                      const value = event.target.value;

                      if (value.length > 2) {
                        fetchOptions(value);
                      } else if (value.length === 0) {
                        fetchOptions();
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          <IconButton
                            color="primary"
                            onClick={() => dialogToggleOpen(true)}
                          >
                            <AddIcon />
                          </IconButton>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />

              <Dialog open={dialogOpen} onClose={handleClose} maxWidth="md">
                <ReactiveForm
                  baseUrl={urlNamespace}
                  ignoredFields={["id", "created", "modified"]}
                  afterSubmit={afterSubmit}
                  title={field.verbose_name}
                  onClose={handleClose}
                />
              </Dialog>

              {errors[name] && (
                <FormHelperText error={true}>{t("Required")}</FormHelperText>
              )}
            </>
          );
        }}
        control={control}
        name={name}
      />
    </React.Fragment>
  );
};

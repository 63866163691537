import React from "react"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { FormHelperText, InputLabel, MenuItem, Select } from "@mui/material"

import { FormInputProps } from "./FormInputProps"

export const FormInputDropdown: React.FC<FormInputProps> = ({
  field,
  control,
  register,
  errors,
}) => {
  const { t } = useTranslation();
  const { name, label, choices, required } = field;
  const fullLabel = `${label}${required ? " *" : ""}`;

  return (
    <>
      <Controller
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState,
        }) => {
          return (
            <>
              <InputLabel htmlFor={name}>{fullLabel}</InputLabel>
              <Select
                {...register(name, { required: required })}
                fullWidth
                label={fullLabel}
                value={value || ""}
                name={name}
                onChange={onChange}
                error={!!error}
                inputProps={register(name, {
                  required: error?.message,
                })}
              >
                {!required && (
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                )}
                {choices.map((option) => {
                  return (
                    <MenuItem key={option.value} value={option.value}>
                      {option.display_name}
                    </MenuItem>
                  );
                })}
              </Select>
              {errors[name] && (
                <FormHelperText error={true}>{t("Required")}</FormHelperText>
              )}
            </>
          );
        }}
        control={control}
        name={name}
      />
    </>
  );
};

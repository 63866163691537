import * as React from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"

import { CheckCircleOutline } from "@mui/icons-material"
import { Button, Typography } from "@mui/material"
import Box from "@mui/material/Box"

import LoadingSpinner from "../components/LoadingSpinner"
import PublicPage from "../layout/PublicPage"
import authService from "../services/auth.service"

function VerifyEmail() {
  const { key } = useParams();
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    const verifyEmail = async () => {
      await authService
        .verifyEmail(key)
        .then(() => {
          setSuccess(true);
        })
        .catch((error) => {
          setError(error);
        });
    };

    verifyEmail();

    return;
  }, []);

  return (
    <PublicPage>
      <Box sx={{ textAlign: "center", py: 8 }}>
        {!success && (
          <>
            {!error && <LoadingSpinner />}
            <Typography>
              {error ? error : t("Verifying E-Mail") + "..."}
            </Typography>
          </>
        )}

        {success && (
          <>
            <CheckCircleOutline
              sx={{ fontSize: 100, color: "success.main" }}
            ></CheckCircleOutline>
            <Typography>{t("E-Mail verified successfully")}</Typography>
            <Button
              variant="contained"
              sx={{ mt: 8 }}
              onClick={() => {
                window.location.href = "/";
              }}
              size="large"
            >
              {t("Login")}
            </Button>
          </>
        )}
      </Box>
      {error && <p>{error.message}</p>}
    </PublicPage>
  );
}

export default VerifyEmail;

import { Timesheet } from "../models/Timesheet"
import API from "./api"
import { BaseService } from "./base.service"

class TimesheetService extends BaseService<Timesheet> {
  BASE_PATH = "/timesheets/";
  MODEL = Timesheet;

  async analytics(params?: {}): Promise<any> {
    return await API.get(`${this.API_V0}${this.BASE_PATH}analytics`, {
      params,
    }).then((response) => response.data);
  }

  async analyticsCurrentMonth(params?: {}): Promise<any> {
    return await API.get(
      `${this.API_V0}${this.BASE_PATH}analytics_current_month`,
      {
        params,
      }
    ).then((response) => response.data);
  }

  async notifyApprovers(timesheet: Timesheet, approvers: string[]) {
    return await API.post(
      `${this.API_V0}${this.BASE_PATH}${timesheet.id}/notify_approvers/`,
      {
        timesheet: timesheet.id,
        approvers,
      }
    ).then((response) => response.data);
  }
}

export default new TimesheetService();

import {
  HeadingLevel,
  HorizontalPositionAlign,
  ImageRun,
  Paragraph,
  VerticalPositionAlign
} from "docx"

import { Profile } from "../../../models/Profile"

export const makeProfile = (profile: Profile, title: string) => {
  const items = [];

  if (profile.contact_email || profile.phone_number || profile.website) {
    items.push(
      new Paragraph({
        text: title,
        heading: HeadingLevel.HEADING_4,
      })
    );
  }

  if (profile.contact_email) {
    items.push(
      new Paragraph({
        text: profile.contact_email,
      })
    );
  }
  if (profile.phone_number) {
    items.push(
      new Paragraph({
        text: profile.phone_number,
      })
    );
  }
  if (profile.website) {
    items.push(
      new Paragraph({
        text: profile.website,
      })
    );
  }

  return items;
};

export const makeProfileImage = (profile: Profile, image: any) => {
  const items = [];

  if (profile.user_photo) {
    items.push(
      new Paragraph({
        style: "profileImage",
        children: [
          new ImageRun({
            data: image,
            transformation: {
              width: 175,
              height: 175,
            },
            floating: {
              horizontalPosition: {
                align: HorizontalPositionAlign.LEFT,
              },
              verticalPosition: {
                align: VerticalPositionAlign.BOTTOM,
              },
            },
          }),
        ],
      })
    );
  }

  return items;
};

import moment from "moment"
import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"

import { Cancel, Check, LockClock } from "@mui/icons-material"
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom"
import { Chip, Grid, Stack, Typography } from "@mui/material"

import EnhancedTable from "../components/EnhancedTable"
import TableLink from "../components/TableLink"
import TimesheetContractCard from "../components/TimesheetContractCard"
import { GlobalContext } from "../contexts/GlobalContext"
import { TimesheetsContext } from "../contexts/TimesheetsContext"
import contractService from "../services/contract.service"
import timesheetService from "../services/timesheet.service"

function TimesheetList() {
  const { t } = useTranslation();
  let { contractId }: any = useParams();
  const { contract, setContract, setTimesheets } = useContext(
    TimesheetsContext
  ) as any;
  const { setBackdrop } = useContext(GlobalContext) as any;

  const formDefault = {
    status: "",
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  };

  const ignoredFields = [
    "id",
    "created",
    "modified",
    "totals",
    "status",
    "archived",
    "contract",
    "customer",
    "status_changed",
    "approval_objs",
  ];

  const headCells = [
    {
      id: "month",
      numeric: false,
      label: t("Name"),
      render: (row: any, headCell: any) => {
        const title = moment(`${row.year}-${row.month}-01`).format("MMMM YYYY");

        return (
          <TableLink
            title={title}
            to={`/timesheets/${contractId}/detail/${row.id}`}
          />
        );
      },
    },
    {
      id: "totals",
      numeric: false,
      label: t("Hours"),
      render: (row: any, headCell: any) => {
        const hours = row[headCell.id].hours;
        const currency = contract.currency;
        const hourlyRate = contract.hourly_rate;

        return (
          <Stack alignItems="start" spacing={1}>
            <Typography variant="body1">
              {hours} {t("Hours")}
            </Typography>

            <Typography variant="body2" color="text.secondary">
              {Math.round(hourlyRate * hours)} {currency}
            </Typography>
          </Stack>
        );
      },
    },
    {
      id: "status",
      numeric: false,
      label: t("Status"),
      render: (row: any, headCell: any) => {
        const status = row[headCell.id];
        const colors = {
          draft: "primary",
          sent_for_approval: "info",
          approved: "success",
          rejected: "error",
        };
        const icons = {
          draft: <HourglassBottomIcon />,
          sent_for_approval: <LockClock />,
          approved: <Check />,
          rejected: <Cancel />,
        };
        const titles = {
          draft: t("Draft"),
          sent_for_approval: t("Submitted"),
          approved: t("Approved"),
          rejected: t("Rejected"),
        };

        return (
          <Chip
            color={colors[status]}
            icon={icons[status]}
            label={titles[status]}
            variant={"outlined"}
            sx={{ textTransform: "capitalize", border: 0 }}
          />
        );
      },
    },
    {
      id: "created",
      numeric: false,
      label: t("Created"),
      render: (row: any, headCell: any) => {
        const date = new Date(row[headCell.id]);

        return moment(date).format("llll");
      },
    },
  ];

  const fetchItems = async (params: any = { archived: false }) => {
    setBackdrop(true);

    if (!contract) {
      setContract(await contractService.detail(contractId));
    }
    const response = await timesheetService.list({
      contract: contractId,
      ...params,
    });
    setTimesheets(response.results);

    setBackdrop(false);
  };

  useEffect(() => {
    fetchItems();

    return () => {
      setTimesheets(null);
    };
  }, []);

  return (
    <>
      {contract && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <EnhancedTable
              title={t("Timesheets")}
              headCells={headCells}
              service={timesheetService}
              archivable={true}
              extraParams={{ contract: contractId }}
              initialValues={formDefault}
              ignoredFields={ignoredFields}
              editable={false}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TimesheetContractCard contract={contract} />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default TimesheetList;

import { useTranslation } from 'react-i18next';

import { ListItem, ListItemText, Typography } from '@mui/material';

export default function EmptyListItem() {
  const { t } = useTranslation();

  return (
    <ListItem sx={{ p: 0 }}>
      <ListItemText color="secondary">
        <Typography variant="body2" color="text.secondary">
          {t("No entries found")}
        </Typography>
      </ListItemText>
    </ListItem>
  );
}

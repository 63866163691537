import moment from "moment"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import {
  CopyAllOutlined,
  Mail,
  MailOutline,
  QuestionMarkOutlined
} from "@mui/icons-material"
import {
  Alert,
  Chip,
  Divider,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Tooltip,
  Typography
} from "@mui/material"

import CopyToClipboard from "../components/CopyToClipboard"
import EnhancedTable from "../components/EnhancedTable"
import { OpportunitiesContext } from "../contexts/OpportunitiesContext"
import { useInterval } from "../hooks/useInterval"
import { useLocalStorage } from "../hooks/useLocalStorage"
import opportunitiesService from "../services/opportunities.service"

export default function OpportunitiesList() {
  const { t } = useTranslation();
  const [user] = useLocalStorage("user", null);
  const [open, setOpen] = useState(false);
  const { opportunities, setOpportunities } = useContext(
    OpportunitiesContext
  ) as any;

  const [prevCount, setPrevCount] = useState<number>(0);

  const opportunityMail = `lancer-${user.user.pk}@lancer.express`;

  const formDefault = {
    name: "",
  };
  const formIgnoreFields = ["id", "created", "modified", "contact_obj"];

  const headCells = [
    {
      id: "name",
      numeric: false,
      label: t("Name"),
    },
    {
      id: "contact_obj",
      numeric: false,
      label: t("Contact"),
      render: (row: any, headCell: any) => {
        const contact = row[headCell.id];
        return contact ? contact.name : "-";
      },
    },
    {
      id: "status",
      numeric: false,
      label: t("Status"),
      render: (row: any, headCell: any) => {
        let color;
        let label;

        const status = row[headCell.id];

        if (status === "open") {
          color = "success";
          label = t("Open");
        } else if (status === "not_interested") {
          color = "warning";
          label = t("Not interested");
        } else if (status === "interview_scheduled") {
          color = "info";
          label = t("Interview scheduled");
        } else {
          color = "error";
        }

        return <Chip label={label} color={color} />;
      },
    },
    {
      id: "created",
      numeric: false,
      label: t("Date received"),
      render: (row: any, headCell: any) => {
        const date = new Date(row[headCell.id]);

        return moment(date).format("llll");
      },
    },
  ];

  async function fetchItems() {
    const response = (await opportunitiesService.get()) as any;

    if (!opportunities) {
      setOpportunities(response.results);
      setPrevCount(response.count);
    }

    return response;
  }

  useEffect(() => {
    fetchItems();

    return;
  }, []);

  useInterval(async () => {
    const response = (await fetchItems()) as any;

    if (response.count !== prevCount) {
      setOpportunities(response.results);
      setPrevCount(response.count);
    }
  }, 5000);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {t("Copied e-mail address to clipboard")}
          </Alert>
        </Snackbar>
        <CopyToClipboard>
          {({ copy }) => (
            <>
              <Paper
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: {
                    xs: "100%",
                  },
                  position: "relative",
                  marginLeft: "auto",
                }}
              >
                <Tooltip
                  title={t(
                    "Forward your emails to the following address to automatically create opportunities:"
                  )}
                >
                  <Typography sx={{ p: "10px" }}>
                    <QuestionMarkOutlined />
                  </Typography>
                </Tooltip>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <Typography
                  sx={{ ml: 1, flex: 1 }}
                  justifyItems={"center"}
                  display={"flex"}
                >
                  <MailOutline sx={{ mr: 1 }} />
                  {opportunityMail}
                </Typography>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <Tooltip title={t("Copy e-mail address to clipboard")}>
                  <IconButton
                    color="primary"
                    sx={{ p: "10px" }}
                    aria-label="directions"
                    onClick={() => {
                      copy(opportunityMail);
                      setOpen(true);
                    }}
                  >
                    <CopyAllOutlined />
                  </IconButton>
                </Tooltip>
              </Paper>
            </>
          )}
        </CopyToClipboard>
      </Grid>

      <Grid item xs={12}>
        <EnhancedTable
          title={t("Opportunities")}
          headCells={headCells}
          service={opportunitiesService}
          searchable={true}
          ignoredFields={formIgnoreFields}
          initialValues={formDefault}
          archivable={false}
        />
      </Grid>
    </Grid>
  );
}

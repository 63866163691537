import * as React from "react"
import { useParams } from "react-router"

import {
  Box,
  Divider,
  Fade,
  Grid,
  Paper,
  Stack,
  Typography
} from "@mui/material"

import { makeTime } from "../common/dateTime"
import LoadingSpinner from "../components/LoadingSpinner"
import TimesheetApprovalForm from "../components/TimesheetApprovalForm"
import TimesheetContractCard from "../components/TimesheetContractCard"
import TimesheetControls from "../components/TimesheetControls"
import TimesheetEntriesTable from "../components/TimesheetEntriesTable"
import TimesheetEvents from "../components/TimesheetEvents"
import { TimesheetsContext } from "../contexts/TimesheetsContext"
import contractService from "../services/contract.service"
import timesheetService from "../services/timesheet.service"
import timesheetEntryService from "../services/timesheetEntry.service"

function TimesheetDetail() {
  let { timesheetId, contractId }: any = useParams();
  const {
    contract,
    setContract,
    timesheet,
    setTimesheet,
    timesheetEntries,
    setTimesheetEntries,
  } = React.useContext(TimesheetsContext) as any;

  React.useEffect(() => {
    const fetchContract = async () => {
      if (!contract) {
        setContract(await contractService.detail(contractId));
      }
    };

    const fetchTimesheet = async () => {
      if (!timesheet) {
        setTimesheet(await timesheetService.detail(timesheetId));
      }
    };

    const fetchTimesheetEntries = async () => {
      const response = await timesheetEntryService.list({
        timesheet: timesheetId,
      });
      setTimesheetEntries(response.results);
    };

    fetchContract();
    fetchTimesheet();
    fetchTimesheetEntries();

    return () => {
      setTimesheetEntries(null);
      setTimesheet(null);
    };
  }, []);

  if (timesheetEntries === null || contract === null || timesheet === null) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Fade in={true}>
        <Grid container>
          <Grid item xs={12} sm={8} sx={{ py: 2 }}>
            <Box flex={1}>
              <TimesheetEntriesTable
                rows={timesheetEntries}
                contract={contract}
                timesheet={timesheet}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              p: {
                md: 2,
              },
            }}
          >
            <Stack spacing={2}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h4" sx={{ mb: 2 }}>
                  {makeTime(`${timesheet.year}-${timesheet.month}`)?.format(
                    "MMMM YYYY"
                  )}
                </Typography>
                <TimesheetControls />
              </Paper>
              <Divider />
              <TimesheetContractCard
                contract={contract}
                showApprovers={false}
              />
              <TimesheetApprovalForm
                contract={contract}
                timesheet={timesheet}
                setTimesheet={setTimesheet}
              />
              {timesheet?.approval_objs &&
                timesheet?.approval_objs.length > 0 && (
                  <>
                    <Divider />
                    <TimesheetEvents timesheet={timesheet} />
                  </>
                )}
            </Stack>
          </Grid>
        </Grid>
      </Fade>
    </>
  );
}

export default TimesheetDetail;

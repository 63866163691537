import Country from "flagit"
import moment from "moment"
import { useTranslation } from "react-i18next"

import { Business, HandshakeOutlined } from "@mui/icons-material"
import FaceIcon from "@mui/icons-material/Face"
import { Chip } from "@mui/material"

import EnhancedTable from "../components/EnhancedTable"
import contactsService from "../services/contacts.service"

export default function ContactsList() {
  const { t } = useTranslation();

  const formDefault = {
    name: "",
  };

  const formIgnoreFields = [
    "id",
    "created",
    "modified",
    "archived",
    "contracts",
  ];

  const headCells = [
    {
      id: "name",
      numeric: false,
      label: t("Name"),
    },
    {
      id: "country",
      numeric: false,
      label: t("Country"),
      render: (row: any, headCell: any) => {
        const value = row[headCell.id];

        return (
          <>
            <Chip
              icon={<Country countryShort={value} size="md" />}
              variant="outlined"
              label={value}
              sx={{ border: 0 }}
            />
          </>
        );
      },
    },
    {
      id: "contact_type",
      numeric: false,
      label: t("Type"),
      render: (row: any, headCell: any) => {
        const value = row[headCell.id];

        if (value === "person") {
          return (
            <Chip
              icon={<FaceIcon />}
              label={t("Person")}
              variant="outlined"
              sx={{ border: 0 }}
            />
          );
        } else if (value === "customer") {
          return (
            <Chip
              icon={<Business />}
              label={t("Company")}
              variant="outlined"
              sx={{ border: 0 }}
            />
          );
        } else if (value === "proxy") {
          return (
            <Chip
              icon={<HandshakeOutlined />}
              label={t("Interim")}
              variant="outlined"
              sx={{ border: 0 }}
            />
          );
        }

        return "-";
      },
    },
    {
      id: "contracts",
      numeric: false,
      label: t("Contracts"),
    },
    {
      id: "created",
      numeric: false,
      label: t("Created"),
      render: (row: any, headCell: any) => {
        const date = new Date(row[headCell.id]);

        return moment(date).format("llll");
      },
    },
  ];

  return (
    <EnhancedTable
      title={t("Contacts")}
      headCells={headCells}
      service={contactsService}
      searchable={true}
      ignoredFields={formIgnoreFields}
      initialValues={formDefault}
      archivable={false}
    />
  );
}

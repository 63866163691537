import { useState } from "react"
import { useTranslation } from "react-i18next"

import { Payment, Subscript } from "@mui/icons-material"
import AccountBoxIcon from "@mui/icons-material/AccountBox"
import TranslateIcon from "@mui/icons-material/Translate"
import {
  Divider,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Typography
} from "@mui/material"
import { Box } from "@mui/system"

import SettingsForm from "../components/SettingsForm"
import CheckoutFormPage from "../components/stripe/CheckoutFormPage"
import UserForm from "../components/UserForm"

function SettingsPage() {
  const { t } = useTranslation();
  const defaultMenuItems = [
    {
      title: "Account",
      icon: <AccountBoxIcon />,
      form: <UserForm />,
    },
    {
      title: "Language",
      icon: <TranslateIcon />,
      form: <SettingsForm />,
    },
    {
      title: "Subscription",
      icon: <Payment />,
      form: <CheckoutFormPage />,
    },
  ];

  const [menuItems] = useState(defaultMenuItems);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [aciveForm, setAciveForm] = useState(defaultMenuItems[0].form);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
    form: any
  ) => {
    setSelectedIndex(index);
    setAciveForm(form);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5" component="div">
          {t("Settings")}
        </Typography>
        <Divider sx={{ marginY: 2 }} />
      </Grid>
      <Grid
        item
        xs={12}
        md={3}
        sx={{
          marginBottom: { xs: 2, md: 0 },
        }}
      >
        <Paper variant="outlined">
          <MenuList sx={{ paddingY: 0 }}>
            {menuItems.map((item, index) => (
              <MenuItem
                sx={{ paddingY: 2 }}
                key={item.title}
                selected={index === selectedIndex}
                onClick={(event) =>
                  handleMenuItemClick(event, index, item.form)
                }
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText>{t(item.title)}</ListItemText>
              </MenuItem>
            ))}
          </MenuList>
        </Paper>
      </Grid>
      <Grid
        item
        xs={12}
        md={9}
        sx={{
          paddingX: {
            xs: 0,
            md: 2,
          },
        }}
      >
        <Paper
          sx={{
            padding: 2,
          }}
        >
          {aciveForm}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default SettingsPage;

import * as React from "react"
import { useTranslation } from "react-i18next"
import { Link as RouterLink, NavLink } from "react-router-dom"

import { AccountCircle, LogoutOutlined, Settings } from "@mui/icons-material"
import MenuIcon from "@mui/icons-material/Menu"
import { Divider } from "@mui/material"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Toolbar from "@mui/material/Toolbar"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"

import { GlobalContext } from "../contexts/GlobalContext"
import { useLocalStorage } from "../hooks/useLocalStorage"
import logo from "../logo.png"

interface App {
  name: string;
  path: string;
}

function NavBarMobile() {
  const { t } = useTranslation();
  const { logOut } = React.useContext(GlobalContext) as any;
  const [user] = useLocalStorage("user", null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const apps: App[] = [
    {
      name: t("Contacts"),
      path: "/contacts",
    },
    {
      name: t("Opportunities"),
      path: "/opportunities",
    },
    {
      name: t("Contracts"),
      path: "/timesheets",
    },
    {
      name: "Vita Curry",
      path: "/vitacurry",
    },
  ];

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl" sx={{ paddingX: 0 }}>
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component={RouterLink}
            to="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img
              id="navbar-logo"
              src={logo}
              alt="Lancer Express"
              height="40"
              style={{ padding: "2px" }}
            />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {apps.map((app: App) => (
                <NavLink
                  to={app.path}
                  key={app.name}
                  style={{ textDecoration: "none" }}
                >
                  {({ isActive }) => (
                    <MenuItem
                      className={isActive ? "active" : ""}
                      onClick={handleCloseNavMenu}
                    >
                      <Typography
                        sx={(theme) => {
                          return {
                            m: 2,
                            color: "primary.main",
                            border: "1px solid transparent",
                            display: "block",
                            fontWeight: `${isActive ? "bold" : ""}`,
                            "&.active": {
                              borderBottom: `3px solid ${theme.palette.primary.contrastText}`,
                            },
                          };
                        }}
                      >
                        {app.name}
                      </Typography>
                    </MenuItem>
                  )}
                </NavLink>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component={RouterLink}
            to="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img
              id="navbar-logo"
              src={logo}
              alt="Lancer Express"
              height="40"
              style={{ padding: "2px" }}
            />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {apps.map((app: App) => (
              <NavLink
                to={app.path}
                key={app.name}
                style={{ textDecoration: "none" }}
              >
                {({ isActive }) => (
                  <Button
                    color={"secondary"}
                    sx={(theme) => {
                      return {
                        m: 2,
                        border: "1px solid transparent",
                        display: "block",
                        fontWeight: `${isActive ? "bold" : ""}`,
                        "&.active": {
                          borderBottom: `3px solid ${theme.palette.primary.contrastText}`,
                        },
                      };
                    }}
                    className={isActive ? "active" : ""}
                    variant={"outlined"}
                  >
                    {app.name}
                  </Button>
                )}
              </NavLink>
            ))}
          </Box>

          <Typography
            component="div"
            sx={{
              flexGrow: 1,
              textAlign: "right",
              display: { xs: "none", md: "block" },
            }}
          >
            {user && (user.user.name ? user.user.name : user.user.email)}
          </Typography>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Tooltip>

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem
                component={RouterLink}
                to="/settings"
                onClick={handleCloseMenu}
              >
                <Settings color="primary" sx={{ mr: 1 }} /> {t("Settings")}
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  logOut();
                }}
              >
                <LogoutOutlined color="error" sx={{ mr: 1 }} /> {t("Logout")}
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default NavBarMobile;

import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"

import {
  Box,
  Fade,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material"

import { TimesheetsContext } from "../contexts/TimesheetsContext"
import timesheetService from "../services/timesheet.service"
import LoadingSpinner from "./LoadingSpinner"

interface RadioProps {
  value: string;
  label: string;
  color:
    | "secondary"
    | "info"
    | "success"
    | "error"
    | "default"
    | "primary"
    | "warning";
}

export default function TimesheetControls() {
  const [loading, setLoading] = useState<boolean>(false);
  const { timesheet, setTimesheet } = useContext(TimesheetsContext) as any;
  const { t } = useTranslation();

  const choices: RadioProps[] = [
    {
      label: t("Draft"),
      value: "draft",
      color: "default",
    },
    {
      label: t("Submitted"),
      value: "sent_for_approval",
      color: "info",
    },
    {
      label: t("Approved"),
      value: "approved",
      color: "success",
    },
    {
      label: t("Rejected"),
      value: "rejected",
      color: "error",
    },
  ];

  const setStatus = (event: any, status: string) => {
    setLoading(true);
    timesheetService
      .update(timesheet.id, { ...timesheet, status })
      .then(() => {
        setTimesheet({
          ...timesheet,
          status,
        });
      })
      .catch(() => null)
      .finally(() => setLoading(false));
  };

  if (!timesheet) {
    return <LoadingSpinner />;
  }

  return (
    <Fade in={true}>
      <Box>
        <Typography variant="h4">
          {Number(timesheet.totals.hours).toLocaleString("de-DE", {
            minimumFractionDigits: 2,
          })}{" "}
          {t("hours")}
        </Typography>
        <Typography variant="h6" sx={{ mb: 4 }}>
          {Number(timesheet.totals.hours / 8).toLocaleString("de-DE", {
            minimumFractionDigits: 4,
          })}{" "}
          {t(`workdays`)}
        </Typography>

        <Typography component="p">{t("Change status")}</Typography>
        <FormControl>
          <RadioGroup value={timesheet.status} onChange={setStatus}>
            {choices.map((choice: RadioProps) => (
              <FormControlLabel
                key={choice.value}
                value={choice.value}
                control={<Radio color={choice.color} />}
                label={choice.label}
                disabled={loading}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    </Fade>
  );
}

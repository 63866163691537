import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"

import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import { FormHelperText } from "@mui/material"

import { FormInputProps } from "./FormInputProps"

const editorConfiguration = {
  toolbar: ["bulletedList", "|", "undo", "redo"],
};

export const FormInputHTMLEditor: React.FC<FormInputProps> = ({
  field,
  control,
  register,
  errors,
}) => {
  const { t } = useTranslation();
  const { name, label, choices } = field;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <>
          <CKEditor
            editor={ClassicEditor}
            inputProps={register(name, {
              required: error?.message,
            })}
            data={value || ""}
            config={editorConfiguration}
            onChange={(event, editor) => {
              const data = editor.getData();
              onChange({ data }.data);
            }}
          />
          {errors[name] && (
            <FormHelperText error={true}>{t("Required")}</FormHelperText>
          )}
        </>
      )}
    />
  );
};

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export default function LanguageSelect() {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth>
      <InputLabel id="language-select-label">{t("Language")}</InputLabel>
      <Select
        labelId="language-select-label"
        id="language-select"
        value={i18next.language || ""}
        label={t("Language")}
        onChange={(e: any) => {
          i18next.changeLanguage(e.target.value);
        }}
      >
        {["de", "en"].map((lang: string) => (
          <MenuItem value={lang} key={lang}>
            {lang}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        {t("Select the locale to use for date formatting.")}
      </FormHelperText>
    </FormControl>
  );
}

import { Alert, AlertTitle, List, ListItem, ListItemText } from "@mui/material";
import { t } from "i18next";
import moment from "moment";
import LoadingSpinner from "../components/LoadingSpinner";

export default function TimesheetApprovalAlert(props) {
  const { approval, showPending } = props;

  const hasStatus = approval.approved_date || approval.rejected_date;

  if (!approval) {
    return <LoadingSpinner />;
  }

  return (
    <Alert
      variant="outlined"
      severity={
        hasStatus ? (approval.approved_date ? "success" : "error") : "info"
      }
      sx={{ bgcolor: "background.paper" }}
    >
      <AlertTitle>
        {hasStatus
          ? approval.approved_date
            ? t("Approved")
            : t("Rejected")
          : t("Pending") + ` (${approval.id})`}
      </AlertTitle>

      <List>
        <ListItem sx={{ p: 0 }}>
          <ListItemText
            primary={hasStatus ? t("Date") : t("Sent")}
            secondary={
              hasStatus
                ? approval.approved_date
                  ? moment(approval.approved_date).format("LLL")
                  : moment(approval.rejected_date).format("LLL")
                : moment(approval.created).fromNow()
            }
          />
        </ListItem>
        {!hasStatus && showPending && (
          <ListItem sx={{ p: 0 }}>
            <ListItemText
              primary={t("Expiry date")}
              secondary={moment(approval.expiry_date)
                .add(7, "days")
                .format("LLL")}
            />
          </ListItem>
        )}
        <ListItem sx={{ p: 0 }}>
          <ListItemText
            primary={hasStatus ? t("By") : t("Approver")}
            secondary={approval.approver?.email}
          />
        </ListItem>
        {approval.rejected_date && approval.comment && (
          <ListItem sx={{ p: 0 }}>
            <ListItemText
              primary={t("Reasoning")}
              secondary={approval.comment}
            />
          </ListItem>
        )}
      </List>
    </Alert>
  );
}

import i18next from "i18next";
import moment from "moment";
import { useState, useEffect } from "react";

export interface Settings {
  timesheets: {
    ampm: boolean;
    momentLocale: string;
  };
}

const defaultSettings: Settings = {
  timesheets: {
    ampm: false,
    momentLocale: "de",
  },
};

const storageKey = "settings";

const resetSettings = (): Settings => {
  localStorage.removeItem(storageKey);
  setSettingsStorage(defaultSettings);

  return defaultSettings;
};

const getSettings = (): Settings => {
  const settings = localStorage.getItem(storageKey);
  if (settings) {
    return JSON.parse(settings);
  }
  return resetSettings();
};

const setSettingsStorage = (settings: Settings): Settings => {
  localStorage.setItem(storageKey, JSON.stringify(settings));

  return settings;
};

export default function useSettings() {
  const storedSettings: Settings = getSettings();
  const [settings, setSettings] = useState<Settings>(storedSettings);

  useEffect(() => {
    setSettingsStorage(settings);
    setSettings(settings);

    moment.locale(settings.timesheets.momentLocale);
    // TODO: set datagrid locale
    // TODO: set mui locale
    // TODO: set i18nnext locale

    return () => {};
  });

  return [settings, setSettings];
}

import React, { useState } from "react"

import { Opportunity } from "../models/Opportunity"

export const OpportunitiesContext: any = React.createContext({});

const OpportunitiesContextProvider = ({ children }: any) => {
  const [opportunities, setOpportunities] = useState<Opportunity[] | null>(
    null
  );
  const [opportunity, setOpportunity] = useState<Opportunity | null>(null);

  return (
    <OpportunitiesContext.Provider
      value={{
        opportunity,
        setOpportunity,
        opportunities,
        setOpportunities,
      }}
    >
      {children}
    </OpportunitiesContext.Provider>
  );
};
export default OpportunitiesContextProvider;

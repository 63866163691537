import { CircularProgress, Grid } from "@mui/material";

function LoadingSpinner() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ paddingTop: "50px" }}
    >
      <Grid item xs={12}>
        <CircularProgress />
      </Grid>
    </Grid>
  );
}

export default LoadingSpinner;

import appSettings from '../common/apiSettings';
import API from './api';
import { getCurrentLang } from './base.service';

const SIGN_UP_URL = "api/auth/register/";
const LOGIN_URL = "api/auth/login/";
const LOGOUT_URL = "api/auth/logout/";
const PASSWORD_RESET_URL = "api/auth/password/reset/";
const PASSWORD_RESET_CONFIRM_URL = "api/auth/password/reset/confirm/";
const EMAIL_CONFIRM_URL = "api/auth/register/verify-email/";
const API_DOMAIN: string = appSettings.api.root;
const API_LANG = getCurrentLang().toLowerCase();

class AuthService {
  async verifyEmail(key: string) {
    return await API.post(`${API_DOMAIN}/${API_LANG}/${EMAIL_CONFIRM_URL}`, {
      key,
    }).then((response) => response);
  }

  async login(email: any, password: any) {
    return await API.post(`${API_DOMAIN}/${API_LANG}/${LOGIN_URL}`, {
      email,
      password,
    }).then((response) => {
      if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
  }

  async logout() {
    return await API.post(`${API_DOMAIN}/${API_LANG}/${LOGOUT_URL}`)
      .then(() => null)
      .catch(() => null)
      .finally(() => {
        localStorage.removeItem("user");
        window.location.reload();
      });
  }

  async register(email: any, password1: any, password2: any) {
    return await API.post(`${API_DOMAIN}/${API_LANG}/${SIGN_UP_URL}`, {
      email,
      password1,
      password2,
    }).then((response) => {
      if (response.data) {
        this.setUser(response.data);
        window.location.reload();
      }

      return response.data;
    });
  }

  async newPassword(email: any) {
    return await API.post(`${API_DOMAIN}/${API_LANG}/${PASSWORD_RESET_URL}`, {
      email,
    }).then((response) => response);
  }

  async newPasswordConfirm(
    uid: any,
    token: any,
    new_password1: any,
    new_password2: any
  ) {
    return await API.post(
      `${API_DOMAIN}/${API_LANG}/${PASSWORD_RESET_CONFIRM_URL}`,
      {
        uid,
        token,
        new_password1,
        new_password2,
      }
    ).then((response) => response);
  }

  getCurrentUser() {
    const user = localStorage.getItem("user");
    if (user) {
      return JSON.parse(user);
    }
    return undefined;
  }

  setUser(user: any) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  isAuthenticated() {
    return !!this.getCurrentUser();
  }
}

export default new AuthService();

import { BaseModel } from "./BaseModel"

export class User extends BaseModel {
  email: string;
  name: string;
  pk: number;
  username: string;
}

export class JWTUser extends BaseModel {
  access_token: string;
  refresh_token: string;
  user: User;
}

import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { FormHelperText, TextField } from "@mui/material"

import { FormInputProps } from "./FormInputProps"

export const FormInputNumber = ({
  field,
  control,
  register,
  errors,
}: FormInputProps) => {
  const { t } = useTranslation();
  const { name, label, required, minValue, maxValue } = field;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <>
          <TextField
            helperText={error ? error.message : null}
            onChange={onChange}
            value={value || ""}
            fullWidth
            label={label}
            variant="outlined"
            error={!!error}
            required={required}
            type={"number"}
            inputProps={register(name, {
              required: error?.message,
              min: minValue,
              max: maxValue,
            })}
            {...register(name, { required: required })}
          />
          {errors[name] && errors[name].type === "required" && (
            <FormHelperText error={true}>{t("Required")}</FormHelperText>
          )}
          {errors[name] && errors[name].type === "min" && (
            <FormHelperText error={true}>
              {t("Cannot be smaller than {{min}}", { min: minValue })}
            </FormHelperText>
          )}
          {errors[name] && errors[name].type === "max" && (
            <FormHelperText error={true}>
              {t("Cannot be larger than {{max}}", { max: maxValue })}
            </FormHelperText>
          )}
        </>
      )}
    />
  );
};

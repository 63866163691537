import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import TimesheetApprovalAlert from "./TimesheetApprovalAlert";

export default function TimesheetEvents(props) {
  const { timesheet } = props;
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {t("Approvals")}
      </Typography>

      {timesheet?.approval_objs.map((approval) => (
        <TimesheetApprovalAlert
          approval={approval}
          key={approval.id}
          showPending
        />
      ))}
    </>
  );
}

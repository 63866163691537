import { Shadows } from '@mui/material/styles/shadows';

const shadows = new Array(25).fill("none") as Shadows;

const themeConfig = {
  palette: {
    type: "light",
    background: {
      default: "#f9f9f9",
    },
    primary: {
      main: "#231f20",
    },
    secondary: {
      main: "#efe6dd",
    },
    error: {
      main: "#bb4430",
      contrastText: "#ffffff",
    },
    warning: {
      main: "#f3dfa2",
    },
    info: {
      main: "#7ebdc2",
      contrastText: "#ffffff",
    },
    success: {
      main: "#849324",
      contrastText: "#ffffff",
    },
    dark: {
      main: "#231f20",
    },
  },
  typography: {
    fontFamily: "Inter",
    button: {
      textTransform: null,
    },
  },
  components: {
    MuiPaper: {
      defaultProps: {
        variant: "outlined" as "outlined",
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          border: "none",
        },
      },
    },
  },
};

export default themeConfig;

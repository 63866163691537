import { useTranslation } from "react-i18next"

import { Divider, Grid, Typography } from "@mui/material"

import CVGenerator from "../components/docx-generator/CVGenerator"
import EducationsList from "../components/EducationsList"
import ExternalProfiles from "../components/ExternalProfiles"
import PortfolioItemsList from "../components/PortfolioItemsList"
import ProfileDisplay from "../components/ProfileDisplay"
import Skills from "../components/Skills"

function VitacurryHome() {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12} md={9}>
        <Typography
          variant="h4"
          sx={{
            marginBottom: {
              xs: 2,
              md: 0,
            },
          }}
        >
          {t("Your profile")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <CVGenerator />
      </Grid>
      <Divider
        sx={{
          width: "100%",
          marginY: 2,
        }}
      />
      <Grid
        item
        xs={12}
        md={3}
        sx={{
          paddingRight: {
            xs: 0,
            md: 1,
          },
          paddingBottom: {
            xs: 2,
            md: 0,
          },
        }}
      >
        <ProfileDisplay />
        <Divider sx={{ border: 0, marginY: 1 }} />
        <EducationsList />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          paddingX: {
            xs: 0,
            md: 1,
          },
          paddingBottom: {
            xs: 2,
            md: 0,
          },
        }}
      >
        <PortfolioItemsList />
      </Grid>
      <Grid
        item
        xs={12}
        md={3}
        sx={{
          paddingLeft: {
            xs: 0,
            md: 1,
          },
          paddingBottom: {
            xs: 2,
            md: 0,
          },
        }}
      >
        <ExternalProfiles />
        <Divider sx={{ border: 0, marginY: 1 }} />
        <Skills />
      </Grid>
    </Grid>
  );
}

export default VitacurryHome;

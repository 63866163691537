export class Timesheet {
  id: any;
  totals: any;
  created: any;
  modified: any;
  status: any;
  status_changed: any;
  year: any;
  month: any;
  contract: any;

  init(timesheet: any) {
    Object.assign(this, {
      ...timesheet,
      id: timesheet.id,
      totals: timesheet.totals,
      created: timesheet.created,
      modified: timesheet.modified,
      status: timesheet.status,
      status_changed: timesheet.status_changed,
      year: timesheet.year,
      month: timesheet.month,
      contract: timesheet.contract,
    });

    return this;
  }

  initBulk(timesheet: any[]) {
    const result: any[] = [];

    timesheet.forEach((timesheetEntry: any) => {
      result.push(new Timesheet().init(timesheetEntry));
    });

    return result;
  }
}

import "moment/locale/de"
import "./App.css"

import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import * as React from "react"
import { initReactI18next } from "react-i18next"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"

import AdapterDateFns from "@mui/lab/AdapterMoment"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import { Alert, Backdrop, CircularProgress, Snackbar } from "@mui/material"
import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { LicenseInfo } from "@mui/x-data-grid-pro"

import ContactsApp from "./apps/ContactsApp"
import OpportunitiesApp from "./apps/OpportunitiesApp"
import TimesheetsApp from "./apps/TimesheetsApp"
import VitacurryApp from "./apps/VitacurryApp"
import { NoUserRoute } from "./components/NoUserRoute"
import { ProtectedRoute } from "./components/ProtectedRoute"
import { GlobalContext } from "./contexts/GlobalContext"
import { useLocalStorage } from "./hooks/useLocalStorage"
import { ProtectedLayout } from "./layout/ProtectedLayout"
import translationDE from "./locales/de.json"
import PublicTimesheetApproval from "./pages/PublicTimesheetApproval"
import SettingsPage from "./pages/SettingsPage"
import Login from "./pages/UserLogin"
import PasswordReset from "./pages/UserPasswordReset"
import PasswordResetConfirmation from "./pages/UserPasswordResetConfirmation"
import SignUp from "./pages/UserSignUp"
import VerifyEmail from "./pages/UserVerifyEmail"
import { alertService } from "./services/alert.service"
import AuthService from "./services/auth.service"
import themeConfig from "./theme-light"

const resources = {
  de: { translation: translationDE },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources,
    fallbackLng: "en",
    supportedLngs: ["de", "en"],
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

LicenseInfo.setLicenseKey(
  "a4e714b6cd123ff4c8589eea1099d341T1JERVI6Mzk1NTAsRVhQSVJZPTE2Nzg3MTIzODgwMDAsS0VZVkVSU0lPTj0x"
);

const theme = createTheme(themeConfig);

function App() {
  const { settings, backdrop } = React.useContext(GlobalContext) as any;
  const [user, setUser] = useLocalStorage("user", null);
  const [subscripions, setSubscripions] = React.useState<any>(null);
  const [alert, setAlert] = React.useState<any>(null);
  const [openAlerts, setOpenAlerts] = React.useState<boolean>(false);

  React.useEffect(() => {
    setUser(AuthService.getCurrentUser());

    setSubscripions(
      alertService.onAlert().subscribe((alert: any) => {
        setAlert(alert);
        setOpenAlerts(true);
      })
    );

    return () => {
      alertService.clear();
      subscripions?.unsubscribe();
    };
  }, []);

  const handleClose = (event: any, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlerts(false);
  };

  return (
    <BrowserRouter>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        locale={settings.timesheets.momentLocale}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Backdrop
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={backdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          {alert && alert.messages && (
            <Box sx={{ display: "flex", bgcolor: "background.default" }}>
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={openAlerts}
                autoHideDuration={alert.autoHideDuration}
                key={alert.id}
                onClose={handleClose}
              >
                <Alert severity={alert.type} sx={{ width: "100%" }}>
                  {alert.messages.map((message: any) => (
                    <div key={message}>
                      <span>{message}</span>
                      <br />
                    </div>
                  ))}
                </Alert>
              </Snackbar>
            </Box>
          )}

          <Box sx={{ flexGrow: 1, bgcolor: "background.default" }}>
            <Routes>
              {!user && <Route path="/" element={<Navigate to="/login" />} />}
              {user && <Route path="/login" element={<Navigate to="/" />} />}
              <Route
                path="/login"
                element={
                  <NoUserRoute>
                    <Login />
                  </NoUserRoute>
                }
              />
              <Route
                path="/register"
                element={
                  <NoUserRoute>
                    <SignUp />
                  </NoUserRoute>
                }
              />
              <Route
                path="/reset-password"
                element={
                  <NoUserRoute>
                    <PasswordReset />
                  </NoUserRoute>
                }
              />
              <Route
                path="/reset/:uid/:token"
                element={
                  <NoUserRoute>
                    <PasswordResetConfirmation />
                  </NoUserRoute>
                }
              />
              <Route
                path="/register/verify_email/:key"
                element={<VerifyEmail />}
              />
              <Route
                path="/tsv/:id/:secret"
                element={<PublicTimesheetApproval />}
              />

              <Route path="/" element={<ProtectedLayout />}>
                <Route path="/timesheets/*" element={<TimesheetsApp />} />
                <Route path="/vitacurry/*" element={<VitacurryApp />} />
                <Route path="/contacts/*" element={<ContactsApp />} />
                <Route path="/opportunities/*" element={<OpportunitiesApp />} />
                <Route
                  path="/settings"
                  element={
                    <ProtectedRoute>
                      <SettingsPage />
                    </ProtectedRoute>
                  }
                />
                <Route path="/" element={<Navigate to={"/timesheets"} />} />
              </Route>
            </Routes>
          </Box>
        </ThemeProvider>
      </LocalizationProvider>
    </BrowserRouter>
  );
}

export default App;

import { useState } from "react"
import { useTranslation } from "react-i18next"

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  TextField,
  Typography
} from "@mui/material"

import { useLocalStorage } from "../hooks/useLocalStorage"
import { alertService } from "../services/alert.service"
import AuthService from "../services/auth.service"
import UserService from "../services/user.service"

function UserForm({ props }: any) {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useLocalStorage("user", null);
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event: any) => {
    if (event.target.value !== user.user[event.target.name]) {
      setLoading(true);
      const newUser = {
        ...user,
        user: { ...user.user, [event.target.name]: event.target.value },
      };

      UserService.update(user.user.pk, newUser).then(() => {
        setUser(newUser);
        AuthService.setUser(newUser);
        setLoading(false);
      });
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    await UserService.delete(user.user.pk).then(() => {
      alertService.info([t("Your account has been deleted.")]);
      AuthService.logout();
    });
  };

  const handleKeyDown = (event: any) => {
    if (event.keyCode == 13) {
      handleChange(event);
    }
  };

  return (
    <div>
      {user && (
        <>
          <Typography variant="subtitle1">{t("Account")}</Typography>
          <FormControl fullWidth>
            <TextField
              label={t("Display Name")}
              onBlur={handleChange}
              onKeyDown={handleKeyDown}
              defaultValue={user.user.name}
              name="name"
              disabled={loading}
            />
            <FormHelperText sx={{ m: 0 }}>
              {t("Used only for display purposes.")}
            </FormHelperText>
          </FormControl>
          <Divider sx={{ mb: 2 }} />
          <Typography variant="subtitle1">{t("Changing password")}</Typography>
          <Typography variant="body2" paragraph={true}>
            {t(
              'If you wish to change your password, please logout and use the "Forgot password?" link on the login page.'
            )}
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Button variant="outlined" color="error" onClick={handleClickOpen}>
            {t("Delete account")}
          </Button>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
              {t("Are you sure you want to delete your account?")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("All your data will be deleted and cannot be recovered.")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={handleClose}>
                {t("Cancel")}
              </Button>
              <Button color="error" onClick={handleDelete} autoFocus>
                {t("Delete")}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </div>
  );
}

export default UserForm;

import { t } from "i18next"
import moment from "moment"
import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router"

import { LoadingButton } from "@mui/lab"
import {
  Alert,
  AlertTitle,
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Typography
} from "@mui/material"

import LoadingSpinner from "../components/LoadingSpinner"
import TimesheetApprovalAlert from "../components/TimesheetApprovalAlert"
import TimesheetPreviewTable from "../components/TimesheetPreviewTable"
import { GlobalContext } from "../contexts/GlobalContext"
import { TimesheetApproval } from "../models/TimesheetApproval"
import timesheetApprovalService from "../services/timesheetApproval.service"

export default function PublicTimesheetApproval() {
  const [approval, setApproval] = useState<TimesheetApproval>();
  const [notFound, setNotFound] = useState(null);
  const { id, secret }: any = useParams();
  const { backdrop, setBackdrop } = useContext(GlobalContext) as any;

  const handleError = (error: any) => {
    if (error.response?.status === 404) {
      setNotFound(true);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const fetchedTimesheet = await timesheetApprovalService
        .detail(id, { secret })
        .catch(handleError);

      if (fetchedTimesheet) {
        setApproval(fetchedTimesheet);
      }
    };
    fetch();
  }, []);

  const onClick = async (decision: string) => {
    setBackdrop(true);

    let newApproval = approval;

    if (decision === "approve") {
      newApproval = await timesheetApprovalService.approve(approval, secret);
    } else if (decision === "reject") {
      newApproval = await timesheetApprovalService.reject(approval, secret);
    }

    setApproval(newApproval);
    setBackdrop(false);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setApproval(new TimesheetApproval().init({ ...approval, [name]: value }));
  };

  if (!approval) {
    return <LoadingSpinner />;
  }

  return (
    <Box sx={{ bgcolor: "background.default" }}>
      <Grid container spacing={2} sx={{ bgcolor: "background.default", p: 4 }}>
        <Grid item xs={12}>
          <h1>
            {t("Timesheet")}{" "}
            {moment(approval?.timesheet.date).format("MMMM YYYY")}{" "}
          </h1>
        </Grid>
        <Grid item xs={8}>
          {approval && <TimesheetPreviewTable timesheet={approval.timesheet} />}
        </Grid>

        <Grid item xs={4}>
          {!(approval?.approved_date || approval?.rejected_date) && (
            <>
              <Stack direction="row" spacing={2}>
                <LoadingButton
                  variant="contained"
                  color="success"
                  sx={{ width: "100%", height: "50px" }}
                  onClick={() => onClick("approve")}
                  disabled={approval?.approved_date}
                  loading={backdrop}
                >
                  {t("Approve")}
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  color="error"
                  sx={{ width: "100%", height: "50px" }}
                  onClick={() => onClick("reject")}
                  disabled={approval?.rejected_date}
                  loading={backdrop}
                >
                  {t("Reject")}
                </LoadingButton>
              </Stack>
              <TextField
                label={t("Comment") + " " + t("(optional)")}
                name="comment"
                value={approval?.comment || ""}
                onChange={onChange}
                multiline
                rows={4}
                fullWidth
                sx={{ mt: 2, bgcolor: "background.paper" }}
              />
              <Divider sx={{ my: 2 }} />
            </>
          )}
          <Paper>
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
              <ListItem>
                <ListItemText
                  primary={t("Hours")}
                  secondary={approval?.timesheet.totals.hours}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={t("Customer")}
                  secondary={approval?.timesheet.contract_obj.contact_obj.name}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={t("Freelancer")}
                  secondary={approval?.owner.name || approval?.owner.username}
                />
              </ListItem>
            </List>
          </Paper>
          {(approval?.approved_date || approval?.rejected_date) && (
            <>
              <Divider sx={{ my: 2 }} />
              <TimesheetApprovalAlert approval={approval} />
            </>
          )}

          {!approval && !notFound && <LoadingSpinner />}
          {notFound && (
            <>
              <Typography variant="h2" component="h2">
                {t("Timesheet not found")}
              </Typography>
              <Alert variant="filled" severity="error">
                The requested timesheet approval could not be found. Please ask
                the timesheet owner to check the link.
              </Alert>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

import * as React from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router"
import { Link as RouterLink, Navigate } from "react-router-dom"

import { LoadingButton } from "@mui/lab"
import { FormControl, FormHelperText, Link } from "@mui/material"
import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

import { useLocalStorage } from "../hooks/useLocalStorage"
import PublicPage from "../layout/PublicPage"
import { JWTUser } from "../models/User"
import AuthService from "../services/auth.service"

export default function SignUp() {
  const { t } = useTranslation();
  const [setUser] = useLocalStorage("user", null);
  const { state }: any = useLocation();
  const [loading, setLoading] = React.useState(false);
  const [redirectToReferrer, setRedirectToReferrer] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const requiredTxt = t("Required");

  const onSubmit = async (data: any) => {
    setLoading(true);

    return await AuthService.register(
      data.email,
      data.password1,
      data.password2
    )
      .then((user: JWTUser) => {
        setUser(user);
        return setRedirectToReferrer(true);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  if (redirectToReferrer === true) {
    return <Navigate to={state ? state.from : "/"} />;
  }

  return (
    <PublicPage>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 2,
        }}
      >
        <Typography component="h1" variant="h2" sx={{ marginY: 8 }}>
          {t("Sign Up")}
        </Typography>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label={t("Email Address")}
                name="email"
                autoComplete="email"
                {...register("email", { required: requiredTxt })}
                error={errors?.email ? true : false}
                helperText={errors?.email ? errors.email.message : null}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                name="password1"
                label={t("Password")}
                type="password"
                id="password1"
                autoComplete="new-password"
                {...register("password1", {
                  required: requiredTxt,
                })}
                error={errors?.password1 ? true : false}
                helperText={errors?.password1 ? errors.password1.message : null}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                name="password2"
                label={t("Password (repeat)")}
                type="password"
                id="password2"
                autoComplete="new-password"
                {...register("password2", {
                  required: requiredTxt,
                })}
                error={errors?.password2 ? true : false}
                helperText={errors?.password2 ? errors.password2.message : null}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="terms_conditions"
                      required={true}
                      inputProps={{ required: true }}
                      {...register("terms_conditions", {
                        required: requiredTxt,
                      })}
                    />
                  }
                  label={t("I accept the terms and conditions.")}
                />
                {errors?.terms_conditions && (
                  <FormHelperText error={true}>{requiredTxt}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            loading={loading}
            color="info"
            size="large"
          >
            {t("Sign Up")}
          </LoadingButton>

          <Grid container justifyContent="flex-end">
            <Grid item>
              <Typography component="div">
                <Link to="/login" underline="none" component={RouterLink}>
                  {t("Already have an account? Sign in")}
                </Link>
              </Typography>

              <RouterLink to="/login"></RouterLink>
            </Grid>
          </Grid>
        </form>
      </Box>
    </PublicPage>
  );
}

import moment from "moment"
import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"

import AllInclusiveIcon from "@mui/icons-material/AllInclusive"
import { Grid, Paper, Stack } from "@mui/material"

import ContractProgressBar from "../components/ContractProgressBar"
import EnhancedTable from "../components/EnhancedTable"
import TableLink from "../components/TableLink"
import { TimesheetEntriesChart } from "../components/TimesheetEntriesChart"
import { TimesheetEntriesCurrentMonth } from "../components/TimesheetEntriesCurrentMonth"
import { GlobalContext } from "../contexts/GlobalContext"
import { TimesheetsContext } from "../contexts/TimesheetsContext"
import { Contract } from "../models/Contract"
import contractService from "../services/contract.service"

function ContractList() {
  const { t } = useTranslation();
  const { contracts, setContracts, setContract } = useContext(
    TimesheetsContext
  ) as any;

  const { setBackdrop } = useContext(GlobalContext) as any;

  const ignoredFields = [
    "id",
    "created",
    "modified",
    "totals",
    "status",
    "archived",
    "contact_obj",
    "provided_by_obj",
    "approvers_objs",
  ];

  const headCells = [
    {
      id: "name",
      numeric: false,
      width: "250px",
      label: t("Name"),
      render: (row: any, headCell: any) => {
        return (
          <>
            <TableLink
              title={row.contact_obj.name}
              to={`/timesheets/${row.id}`}
            >
              <ContractProgressBar contract={row} />
            </TableLink>
          </>
        );
      },
    },
    {
      id: "provided_by",
      numeric: false,
      label: t("via"),
      render: (row: any, headCell: any) => {
        return row.provided_by_obj?.name || "-";
      },
    },
    {
      id: "hours_max",
      numeric: false,
      label: t("Booked"),
      render: (row: Contract, headCell: any) => {
        if (!row.hours_max || row.hours_max === 0) {
          return <AllInclusiveIcon />;
        }
        return (
          <Stack spacing={1}>
            <span>
              {row.hours_max} {t("Hours")}
            </span>
            <small>
              {Math.round(row.hours_max * row.hourly_rate).toLocaleString()}{" "}
              {row.currency || ""}
            </small>
          </Stack>
        );
      },
    },
    {
      id: "totals",
      numeric: false,
      label: t("Worked"),
      render: (row: any, headCell: any) => {
        return (
          <Stack spacing={1}>
            <span>
              {row.totals.hours} {t("Hours")}
            </span>
            <small>
              {Math.round(row.totals.hours * row.hourly_rate).toLocaleString()}{" "}
              {row.currency || ""}
            </small>
          </Stack>
        );
      },
    },
    {
      id: "ramaining",
      numeric: false,
      label: t("remaining"),
      render: (row: any, headCell: any) => {
        if (!row.hours_max || row.hours_max === 0) {
          return <AllInclusiveIcon />;
        }

        return (
          <Stack spacing={1}>
            <span>
              {Math.round(
                row.hours_max - (row.manual_hours || row.totals.hours)
              ).toLocaleString()}{" "}
              {t("Hours")}
            </span>
            <small>
              {Math.round(
                row.hourly_rate * row.hours_max -
                  row.hourly_rate * (row.manual_hours || row.totals.hours)
              ).toLocaleString()}{" "}
              {row.currency || ""}
            </small>
          </Stack>
        );
      },
    },
    {
      id: "hourly_rate",
      numeric: false,
      label: t("Hourly rate"),
      width: "100px",
      render: (row: any, headCell: any) => {
        return `${row.hourly_rate} ${row.currency ? row.currency : ""}`;
      },
    },
    {
      id: "start_date",
      numeric: false,
      label: t("Start"),
      width: "200px",
      render: (row: any, headCell: any) => {
        const date = row[headCell.id];

        return date ? moment(date).format("LL") : <AllInclusiveIcon />;
      },
    },
    {
      id: "end_date",
      numeric: false,
      label: t("End"),
      width: "200px",
      render: (row: any, headCell: any) => {
        if (!row[headCell.id]) {
          return <AllInclusiveIcon />;
        }

        const date = new Date(row[headCell.id]);
        return moment(date).format("LL");
      },
    },
  ];

  const fetchItems = async (params: any = { archived: false }) => {
    setBackdrop(true);
    const response: any = await contractService.list(params);
    setContracts(response.results);
    setBackdrop(false);
  };

  useEffect(() => {
    setContract(null);
    fetchItems();

    return;
  }, []);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={9}>
          <Paper sx={{ p: 2, height: "250px" }}>
            <TimesheetEntriesChart />
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper sx={{ p: 2, height: "250px" }}>
            <TimesheetEntriesCurrentMonth />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <EnhancedTable
            title={t("Contracts")}
            headCells={headCells}
            service={contractService}
            archivable={true}
            orderByDefault="start_date"
            searchable={true}
            ignoredFields={ignoredFields}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default ContractList;

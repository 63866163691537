let appSettings: any;

const baseSettings = {};

if (process.env.NODE_ENV === "production") {
  appSettings = {
    ...baseSettings,
    api: {
      root: "https://api.lancer.express",
    },
  };
} else {
  appSettings = {
    ...baseSettings,
    api: {
      root: "http://localhost:8000",
    },
  };
}

export default appSettings;

import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"

import { Card, CardContent, CardHeader } from "@mui/material"

import { VitycurryContext } from "../contexts/VitycurryContext"
import { Education } from "../models/Education"
import { ExternalProfile } from "../models/ExternalProfile"
import educationService from "../services/education.service"
import ActionsDropdown from "./ActionsDropdown"
import AddIcon from "./AddIcon"
import EmptyListItem from "./EmptyListItem"
import FormPopUp from "./FormPopUp"
import LoadingSpinner from "./LoadingSpinner"

export default function EducationsList() {
  const { t } = useTranslation();
  const { educations, setEducations } = useContext(VitycurryContext) as any;

  const formDefault: ExternalProfile = {
    source: "",
    url: "",
  };

  const formIgnoreFields = ["id", "created", "modified", "profile"];

  useEffect(() => {
    async function fetchItems() {
      const response = await educationService.list();
      setEducations(response.results);
    }

    fetchItems();

    return;
  }, []);

  if (educations == null) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Card variant="outlined">
        <CardHeader
          title={t("Education")}
          subheader={t("Schools, Universities, etc.")}
          sx={{ paddingBottom: 0 }}
          action={
            <FormPopUp
              title={"Add education"}
              btnText={"Add"}
              buttonType={"card-action"}
              initialValues={formDefault}
              setInstanceList={setEducations}
              instanceList={educations}
              icon={<AddIcon />}
              baseUrl={educationService.BASE_PATH}
              ignoredFields={formIgnoreFields}
            />
          }
        />
        <CardContent>
          {educations.map((education: Education) => (
            <Card variant="outlined" sx={{ mb: 1 }} key={education.id}>
              <CardHeader
                title={education.title}
                subheader={`${education.start_date_year} - ${education.end_date_year}, ${education.location}`}
                titleTypographyProps={{ variant: "subtitle1" }}
                subheaderTypographyProps={{ variant: "subtitle2" }}
                action={
                  <ActionsDropdown
                    popUpTitle={t("Edit")}
                    instance={education}
                    instanceList={educations}
                    setInstanceList={setEducations}
                    deletable={true}
                    editable={true}
                    baseUrl={educationService.BASE_PATH}
                    ignoredFields={formIgnoreFields}
                  />
                }
              />
            </Card>
          ))}
        </CardContent>

        {educations.length == 0 && (
          <CardContent>
            <EmptyListItem />
          </CardContent>
        )}
      </Card>
    </>
  );
}

import moment from "moment"
import { useTranslation } from "react-i18next"

import AllInclusiveIcon from "@mui/icons-material/AllInclusive"
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Stack,
  Typography
} from "@mui/material"

import ContractProgressBar from "./ContractProgressBar"

export default function TimesheetContractCard(props: any) {
  const { t } = useTranslation();
  const { contract, showApprovers = true } = props;

  return (
    <>
      <Card variant="outlined">
        <CardHeader
          title={contract.contact_obj.name}
          subheader={
            <>
              <Typography variant="body2">{t("Progress")}</Typography>
              <ContractProgressBar contract={contract} showRevenue={true} />
            </>
          }
          sx={{ paddingBottom: 0 }}
          // action={
          //   <ActionsDropdown
          //     popUpTitle={t("Edit")}
          //     instance={contract}
          //     instanceList={[]}
          //     setInstanceList={setContract}
          //     editable={true}
          //     deletable={false}
          //     baseUrl={contractService.BASE_PATH}
          //     ignoredFields={[
          //       "id",
          //       "created",
          //       "modified",
          //       "totals",
          //       "status",
          //       "archived",
          //       "contact_obj",
          //       "provided_by_obj",
          //       "approvers_objs",
          //     ]}
          //   />
          // }
        />
        <CardContent>
          <Stack spacing={0}>
            <Typography variant="body2">{t("Reference")}</Typography>
            <Typography variant="h6" sx={{ mb: 1 }}>
              {contract.contract_id || "-"}
            </Typography>
            <Typography variant="body2">{t("Hourly rate")}</Typography>
            <Typography variant="h6" sx={{ mb: 1 }}>
              {contract.hourly_rate} {contract.currency || ""}
            </Typography>
            <Typography variant="body2">{t("Duration")}</Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              {contract.start_date ? (
                moment(contract.start_date).format("LL")
              ) : (
                <AllInclusiveIcon />
              )}
              <br />
              {contract.end_date ? (
                moment(contract.end_date).format("LL")
              ) : (
                <AllInclusiveIcon />
              )}
            </Typography>
          </Stack>
        </CardContent>
      </Card>

      {showApprovers && contract.approvers_objs?.length > 0 && (
        <>
          <Typography variant="subtitle1">{t("Approvers")}</Typography>
          {contract.approvers_objs.map((approver: any) => (
            <Paper sx={{ p: 2, mb: 1 }} key={approver.id}>
              <Typography variant="subtitle1">{approver.name}</Typography>
              {approver.email && <small>{approver.email}</small>}
            </Paper>
          ))}
        </>
      )}
    </>
  );
}

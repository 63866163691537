import React, { useState } from "react";

export const VitycurryContext: any = React.createContext({});

const VitycurryContextProvider = ({ children }: any) => {
  const [profiles, setProfiles] = useState(null);
  const [educations, setEducations] = useState(null);
  const [externalProfiles, setExternalProfiles] = useState(null);
  const [portfolioItems, setPortfolioItems] = useState(null);
  const [skills, setSkills] = useState(null);

  return (
    <VitycurryContext.Provider
      value={{
        profiles,
        setProfiles,
        educations,
        setEducations,
        externalProfiles,
        setExternalProfiles,
        portfolioItems,
        setPortfolioItems,
        skills,
        setSkills,
      }}
    >
      {children}
    </VitycurryContext.Provider>
  );
};
export default VitycurryContextProvider;

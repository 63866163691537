import * as React from "react"
import { useTranslation } from "react-i18next"

import { LoadingButton } from "@mui/lab"
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from "@mui/material"
import Typography from "@mui/material/Typography"

import timesheetService from "../services/timesheet.service"

export default function TimesheetApprovalForm(props) {
  const { contract, timesheet, setTimesheet } = props;
  const [selected, setSelected] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<string>("");

  const { t } = useTranslation();

  React.useEffect(() => {
    setSelected(contract.approvers_objs.map((option) => option.id));
  }, []);

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const onApprovalSubmit = async () => {
    setLoading(true);
    setErrors("");

    const approvals = await timesheetService
      .notifyApprovers(timesheet, selected)
      .catch((error) => {
        setErrors(error.message);
      })
      .finally(() => setLoading(false));

    if (approvals) {
      setTimesheet({
        ...timesheet,
        approval_objs: [...approvals, ...timesheet.approval_objs],
      });
    }
  };

  return (
    <>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Typography variant="h6" sx={{ p: 2 }}>
          {t("Approvers")}
        </Typography>
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={"medium"}>
            <TableBody>
              {contract.approvers_objs.map((option: any) => (
                <TableRow key={option.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={selected.indexOf(option.id) !== -1}
                      inputProps={{
                        "aria-labelledby": option.email,
                      }}
                      onClick={(event) => handleClick(event, option.id)}
                    />
                  </TableCell>
                  <TableCell>{option.name || "-"}</TableCell>
                  <TableCell>{option.email || "-"}</TableCell>
                </TableRow>
              ))}

              {contract.approvers_objs.length === 0 && (
                <TableRow>
                  <TableCell colSpan={3}>
                    {t("No approvers selected")}
                  </TableCell>
                </TableRow>
              )}

              <TableRow style={{ height: 53 }}>
                <TableCell colSpan={3}>
                  <LoadingButton
                    variant="contained"
                    onClick={onApprovalSubmit}
                    disabled={selected.length === 0}
                    loading={loading}
                    sx={{ width: "100%" }}
                  >
                    {t("Send approval request")}
                  </LoadingButton>
                  {errors && (
                    <Typography color={"error"} sx={{ pt: 2 }}>
                      {errors}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

import * as React from "react"
import { useTranslation } from "react-i18next"

import { Button, Fab, MenuItem } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import IconButton from "@mui/material/IconButton"

import AddIcon from "./AddIcon"
import EditIcon from "./EditIcon"
import ReactiveForm from "./ReactiveForm"

export interface FormPopUpProps {
  baseUrl: string;
  ignoredFields: string[];
  buttonType: "fab" | "button" | "card-action" | "menu-item";
  title: string;
  instanceList: any[];

  // optional
  setInstanceList?: any;
  initialValues?: { [key: string]: any };
  handleCloseMenu?: any;
  setInstance?: any;
  popUpTitle?: string;
  instance?: any;
  deletable?: boolean;
  editable?: boolean;
  btnText?: string;
  icon?: any;
  archivable?: boolean;
  extraParams?: { [key: string]: any };
  fullScreen?: boolean;
  customSetInstanceList?: any;
}

export default function FormPopUp(props: FormPopUpProps) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const {
    baseUrl,
    btnText,
    buttonType,
    extraParams,
    fullScreen,
    handleCloseMenu,
    icon,
    ignoredFields,
    initialValues,
    instance,
    instanceList,
    setInstance,
    setInstanceList,
    title,
    customSetInstanceList,
  } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const afterSubmit = (item: any) => {
    if (instance) {
      if (!instanceList) {
        setInstance([item]);
      } else {
        const newList = instanceList.map((oldItem) => {
          if (oldItem.id === instance.id) {
            const updatedItem = Object.assign({}, oldItem, item);
            return updatedItem;
          }

          return oldItem;
        });

        setInstanceList(newList);
      }
    } else if (customSetInstanceList) {
      customSetInstanceList(item);
    } else {
      setInstanceList([item, ...instanceList]);
    }

    handleClose();
    handleCloseMenu();
  };

  return (
    <>
      {buttonType === "fab" && (
        <Fab
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
          }}
          variant="extended"
          size="medium"
          color="primary"
          aria-label="add"
          onClick={handleClickOpen}
        >
          <AddIcon sx={{ mr: 1 }} /> {t(btnText)}
        </Fab>
      )}
      {buttonType === "button" && (
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          {icon} {t(btnText)}
        </Button>
      )}
      {buttonType === "card-action" && (
        <IconButton color="primary" onClick={handleClickOpen}>
          {icon && icon}
        </IconButton>
      )}
      {buttonType === "menu-item" && (
        <>
          <MenuItem
            onClick={() => {
              handleClickOpen();
              handleCloseMenu();
            }}
          >
            <EditIcon text={true} sx={{ mr: 1 }} />
          </MenuItem>
        </>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullScreen={fullScreen === false ? false : true}
      >
        <ReactiveForm
          afterSubmit={afterSubmit}
          baseUrl={baseUrl}
          extraParams={extraParams}
          ignoredFields={ignoredFields}
          initialValues={initialValues}
          instance={instance}
          onClose={handleClose}
          title={title}
        />
      </Dialog>
    </>
  );
}

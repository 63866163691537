import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import FileUpload from "react-mui-fileuploader"

import { FormInputProps } from "./FormInputProps"

export const FormInputFileUpload: React.FC<FormInputProps> = ({
  field,
  control,
}) => {
  const { t } = useTranslation();
  const { name, label } = field;

  const handleFileUploadError = (error: any) => {
    console.error(error);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <FileUpload
          multiFile={false}
          disabled={false}
          title={label}
          header={t("Drag & drop")}
          leftLabel={t("or")}
          rightLabel=""
          buttonLabel={t("Select file to upload")}
          buttonRemoveLabel={t("Remove")}
          maxFileSize={1}
          maxUploadFiles={1}
          maxFilesContainerHeight={100}
          allowedExtensions={["jpg", "jpeg", "png"]}
          onFilesChange={(files) => {
            onChange(files[0]?.path);
          }}
          onError={handleFileUploadError}
          imageSrc={value}
        />
      )}
    />
  );
};

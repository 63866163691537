import * as React from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"

import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

import Copyright from "../components/Copyright"
import PublicPage from "../layout/PublicPage"
import AuthService from "../services/auth.service"

function PasswordResetConfirmation() {
  const { t } = useTranslation();
  let { uid, token }: any = useParams();
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console

    const creds = {
      new_password1: data.get("new_password1"),
      new_password2: data.get("new_password2"),
    };
    return AuthService.newPasswordConfirm(
      uid,
      token,
      creds.new_password1,
      creds.new_password2
    )
      .then(() => {
        window.location.href = "/";
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <PublicPage>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("Set new password")}
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="new_password1"
                label={t("Password")}
                type="password"
                id="new_password1"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="new_password2"
                label={t("Password (repeat)")}
                type="password"
                id="new_password2"
                autoComplete="new-password"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
          >
            {t("Save")}
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </PublicPage>
  );
}

export default PasswordResetConfirmation;

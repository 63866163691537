import { Link as RouterLink } from "react-router-dom"

import { Box, createTheme, CssBaseline, ThemeProvider } from "@mui/material"

import Copyright from "../components/Copyright"
import logo from "../logoDark.png"
import themeConfig from "../theme-light"

const theme = createTheme(themeConfig);

function PublicPage(props) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Box
          component="main"
          sx={{
            textAlign: "center",
          }}
        >
          <RouterLink to="/">
            <img
              id="navbar-logo"
              src={logo}
              alt="Lancer Express"
              height="100"
            />
          </RouterLink>
        </Box>
        <Box>{props ? props.children : null}</Box>

        <Copyright sx={{ mt: 5 }} />
      </Box>
    </ThemeProvider>
  );
}

export default PublicPage;

import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { FormHelperText, TextField } from "@mui/material"

import { FormInputProps } from "./FormInputProps"

const isValidEmail = (email) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

export const FormInputText = ({
  field,
  control,
  register,
  errors,
  type,
}: FormInputProps) => {
  const { t } = useTranslation();

  const { name, label, required } = field;

  const handleEmailValidation = (email) => {
    if (email === "") {
      return true;
    }

    return isValidEmail(email);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => {
        return (
          <>
            <TextField
              helperText={error ? error.message : null}
              onChange={onChange}
              value={value || ""}
              fullWidth
              label={label}
              variant="outlined"
              error={!!error}
              required={required}
              type={type ? type : "text"}
              inputProps={register(name, {
                required: error?.message,
              })}
              {...register(name, {
                required: required,
                validate: type === "email" ? handleEmailValidation : null,
              })}
            />
            {errors[name] && errors[name].type === "required" && (
              <FormHelperText error={true}>{t("Required")}</FormHelperText>
            )}
            {errors[name] && errors[name].type === "validate" && (
              <FormHelperText error={true}>
                {t("Please enter a correct E-Mail address")}
              </FormHelperText>
            )}
          </>
        );
      }}
    />
  );
};

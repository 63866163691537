import { useTranslation } from "react-i18next"
import { Link as RouterLink } from "react-router-dom"
import useBreadcrumbs from "use-react-router-breadcrumbs"

import { Box, Breadcrumbs } from "@mui/material"
import Chip from "@mui/material/Chip"
import Link from "@mui/material/Link"
import { emphasize, styled } from "@mui/material/styles"

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip; // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

export default function SimpleBreadcrumbs({ contract, timesheet }: any) {
  const { t } = useTranslation();
  const routes = [
    {
      path: "/",
      breadcrumb: t("Contracts"),
    },
    {
      path: "/timesheets/:contractId",
      breadcrumb: t("Timesheets"),
    },
    {
      path: `/timesheets/:contractId/detail/:timesheetId`,
      breadcrumb: t("Entries"),
    },
    {
      path: "/settings",
      label: t("Settings"),
    },
  ];

  const breadcrumbs = useBreadcrumbs(routes, { disableDefaults: true });

  return (
    <>
      {breadcrumbs.length > 1 && (
        <Box sx={{ paddingX: 2, pt: 2 }}>
          <Breadcrumbs>
            {breadcrumbs.map(({ match, breadcrumb }) => {
              const isActive = window.location.pathname === match.pathname;

              return (
                <Link
                  key={match.pathname}
                  to={match.pathname}
                  underline="hover"
                  component={RouterLink}
                  color={isActive ? "text.primary" : "inherit"}
                  sx={{ fontWeight: isActive ? "bold" : "" }}
                >
                  {breadcrumb}
                </Link>
              );
            })}
          </Breadcrumbs>
        </Box>
      )}
    </>
  );
}

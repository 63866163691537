import * as React from "react"
import { useTranslation } from "react-i18next"

import { MailOutlined } from "@mui/icons-material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

import PublicPage from "../layout/PublicPage"
import AuthService from "../services/auth.service"

export default function PasswordReset() {
  const { t } = useTranslation();
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const creds = {
      email: data.get("email"),
    };

    return AuthService.newPassword(creds.email).then(() => {
      setSuccess(true);
    });
  };

  return (
    <PublicPage>
      <Grid container alignItems={"center"} justifyContent={"center"}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            p: {
              xs: 2,
              md: 0,
            },
          }}
        >
          {success && (
            <Typography
              variant="body1"
              sx={{ textAlign: "center", paddingY: 8 }}
            >
              <MailOutlined sx={{ fontSize: "2em" }} /> <br />
              {t(
                "We have send you an E-Mail with instructions on resetting your password."
              )}
            </Typography>
          )}

          {!success && (
            <Box>
              <Typography variant="h5" sx={{ marginTop: 8 }}>
                {t("Reset Password")}
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={loading}
                >
                  {t("Reset Password")}
                </Button>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </PublicPage>
  );
}

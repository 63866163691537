import moment, { Moment } from "moment"

import { TimesheetEntry } from "../models/TimesheetEntry"

export function makeTime(
  date: string | null | Moment,
  format: any = null
): Moment | null {
  let formats = [
    "YYYY-MM-DD",
    "YYYY-MM-DD HH:mm",
    "YYYY-MM-DD HH:mm:ss",
    "HH:mm",
    "HH:mm A",
    "HH:mm:ss",
    "YYYY-MM-DD HH:mm",
    "YYYY-MM-DDTHH:mm:ss.SSSZZ ",
    "YYYY-MM-DDTHH:mm:ssZZ",
    "YYYY-MM",
    "YYYY-M",
  ];

  if (date === null) {
    return null;
  }

  if (format !== null) {
    formats = [format];
  }

  return moment(`${date}`, formats, true);
}

export function getTotal(entry: TimesheetEntry) {
  let total = 0;

  if (entry.start_1 && entry.end_1) {
    total += +moment.duration(entry.end_1.diff(entry.start_1)).asHours();
  }

  if (entry.start_2 && entry.end_2) {
    total += +moment.duration(entry.end_2.diff(entry.start_2)).asHours();
  }

  return Number(total.toFixed(2));
}

export function getTotalTime(entry: TimesheetEntry) {
  let total;

  if (entry.start_1 && entry.end_1) {
    total = moment.duration(entry.end_1.diff(entry.start_1));
  }

  if (entry.start_2 && entry.end_2) {
    total.add(moment.duration(entry.end_2.diff(entry.start_2)));
  }

  if (total) {
    const formattedTotal = moment.utc(total.as("milliseconds")).format("HH:mm");
    return formattedTotal;
  } else {
    return null;
  }
}

export function getTimesheetTotal(rows: TimesheetEntry[]) {
  let hoursTotal = 0;

  rows.map((entry: TimesheetEntry) => {
    hoursTotal += getTotal(entry);
  });

  return Number(hoursTotal.toFixed(2));
}

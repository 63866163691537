import { useContext, useEffect } from "react"
import { Route, Routes } from "react-router-dom"

import { ProtectedRoute } from "../components/ProtectedRoute"
import { GlobalContext } from "../contexts/GlobalContext"
import TimesheetsContextProvider from "../contexts/TimesheetsContext"
import ContractList from "../pages/ContractList"
import TimesheetDetail from "../pages/TimesheetDetail"
import TimesheetList from "../pages/TimesheetList"

function TimesheetsApp() {
  const { setCurrentApp } = useContext(GlobalContext);

  useEffect(() => {
    setCurrentApp("timesheets");

    return;
  }, []);

  return (
    <>
      <TimesheetsContextProvider
        children={
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <ContractList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/:contractId/detail/:timesheetId"
              element={
                <ProtectedRoute>
                  <TimesheetDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/:contractId"
              element={
                <ProtectedRoute>
                  <TimesheetList />
                </ProtectedRoute>
              }
            />
          </Routes>
        }
      />
    </>
  );
}

export default TimesheetsApp;

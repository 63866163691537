import { t } from "i18next"
import moment, { Moment } from "moment"
import { useCallback, useContext, useEffect } from "react"

import { TimePicker } from "@mui/lab"
import { Alert, Box, Snackbar, TextField, Typography } from "@mui/material"
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridPreProcessEditCellProps,
  GridRenderEditCellParams,
  GridToolbar,
  useGridApiRef
} from "@mui/x-data-grid-pro"

import { getTimesheetTotal, getTotal, getTotalTime } from "../common/dateTime"
import { GlobalContext } from "../contexts/GlobalContext"
import { TimesheetsContext } from "../contexts/TimesheetsContext"
import timesheetEntryService from "../services/timesheetEntry.service"
import LoadingSpinner from "./LoadingSpinner"

export default function TimesheetEntriesTable({ ...props }: any) {
  let { rows } = props;
  const apiRef = useGridApiRef();
  const { timesheet, setTimesheet } = useContext(TimesheetsContext) as any;

  const columns: GridColDef[] = [
    {
      field: "date",
      headerName: t("Date"),
      type: "date",
      renderCell: renderDate,
      valueFormatter: ({ value }) => `${value.format("L")}`,
    },
    {
      field: "start_1",
      headerName: t("Start"),
      editable: true,
      ...defaultColAttributes,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) =>
        runValidations(params, "start_1"),
    },
    {
      field: "end_1",
      headerName: t("End"),
      editable: true,
      ...defaultColAttributes,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) =>
        runValidations(params, "end_1"),
    },
    {
      field: "start_2",
      headerName: t("Start") + " 2",
      editable: true,
      ...defaultColAttributes,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) =>
        runValidations(params, "start_2"),
    },
    {
      field: "end_2",
      headerName: t("End") + " 2",
      editable: true,
      ...defaultColAttributes,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) =>
        runValidations(params, "end_2"),
    },
    {
      field: "comment",
      headerName: t("Comment"),
      editable: true,
      type: "string",
      flex: 1,
    },
    {
      field: "total",
      headerName: t("Hours"),
      editable: false,
      sortable: false,
      valueGetter: (params: any) => {
        const total = getTotal(params.row);
        return total !== 0
          ? Number(total).toLocaleString("de-DE", {
              minimumFractionDigits: 2,
            })
          : "";
      },
    },
    {
      field: "total_time",
      headerName: t("Time"),
      editable: false,
      sortable: false,
      valueGetter: (params: any) => {
        const value = params.row;

        if (!value) {
          return "";
        }

        const total = getTotalTime(value);
        return total;
      },
    },
  ];

  useEffect(() => {
    return apiRef.current.subscribeEvent(
      "cellModeChange",
      (params, event) => {
        event.defaultMuiPrevented = true;
      },
      { isFirst: true }
    );
  }, []);

  const setTotal = (rows: any) => {
    setTimesheet({
      ...timesheet,
      totals: {
        hours: getTimesheetTotal(rows),
      },
    });
  };

  const handleCellClick = useCallback(
    (params: GridCellParams) => {
      if (params.isEditable) {
        apiRef.current.setCellMode(params.id, params.field, "edit");
      }
    },
    [apiRef]
  );

  const onCellEditStop = ({ value, field, id }: any, event: any) => {
    if (!value && !event.target.value) {
      return;
    }

    if (event.keyCode === 46 || event.keyCode === 8) {
      value = null;
    }

    if (value && value.isValid && value.isValid()) {
      value = `${value.format("HH:mm")}`;
    }
    const entry = {
      id,
      [field]: value || event.target.value,
    };

    timesheetEntryService.update(entry.id, entry);

    setTotal([...apiRef.current.getRowModels().values()]);
  };

  if (!rows) {
    return <LoadingSpinner />;
  }

  return (
    <DataGridPro
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      pageSize={50}
      rowsPerPageOptions={[50]}
      onCellEditStop={onCellEditStop}
      onCellClick={handleCellClick}
      density="compact"
      autoHeight
      hideFooter
      disableSelectionOnClick
      sx={{ backgroundColor: "background.paper" }}
      components={{ Toolbar: GridToolbar }}
    />
  );
}

function renderTime(props: any) {
  const { value } = props;
  const { settings } = useContext(GlobalContext) as any;

  try {
    if (settings.timesheets.ampm) {
      return `${value.format("hh:mm A  ")}`;
    } else {
      return `${value.format("HH:mm")}`;
    }
  } catch (e) {
    return <>{value}</>;
  }
}

export function renderDate(props: any) {
  const { value } = props;

  const elem = value !== null ? value.format("L dd") : null;

  if (elem && value.isSame(new Date(), "day")) {
    return (
      <Typography
        variant="overline"
        display="block"
        sx={{
          fontWeight: "bold",
        }}
      >
        {elem}
      </Typography>
    );
  }

  return (
    <Typography variant="overline" display="block">
      {elem}
    </Typography>
  );
}

function TimeEditInputCell(props: GridRenderEditCellParams) {
  const { id, value, api, field, error, errorMsg } = props;
  const { settings } = useContext(GlobalContext) as any;

  const handleChange = async (newValue: any) => {
    api.setEditCellValue({
      id,
      field,
      value: newValue,
    });
    await api.commitCellChange({ id, field });
  };

  const handleRef = (element: any) => {
    if (element) {
      element.focus();
    }
  };

  return (
    <TimePicker
      inputRef={handleRef}
      ampm={settings.timesheets.ampm}
      value={value}
      onChange={handleChange}
      desktopModeMediaQuery="(min-width: 0px)"
      renderInput={(params: any) => {
        return (
          <>
            <TextField
              {...params}
              error={error}
              variant="standard"
              sx={{ p: 1, svg: { display: "none" } }}
            />
            {error && errorMsg && (
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={error}
              >
                <Alert severity="error" sx={{ width: "100%" }}>
                  {errorMsg}
                </Alert>
              </Snackbar>
            )}
          </>
        );
      }}
    />
  );
}

function renderTimeEditInputCell(params: GridRenderEditCellParams) {
  return <TimeEditInputCell {...params} />;
}

function runValidations(params: GridPreProcessEditCellProps, field: string) {
  let hasError = null;
  let errorMsg = undefined;

  if (params.props.value) {
    if (field === "end_1") {
      if (params.row.start_1 && params.row.start_1 >= params.props.value) {
        hasError = true;
        errorMsg = "End time must be after start time";
      }
      if (params.row.start_2 && params.row.start_2 <= params.props.value) {
        hasError = true;
        errorMsg = "Start 2 must be smaller than End";
      }
    } else if (field === "end_2") {
      if (params.row.start_2 && params.row.start_2 >= params.props.value) {
        hasError = true;
        errorMsg = "End 2 time must be after Start and Start 2";
      }
      if (params.row.start_1 && params.row.start_1 >= params.props.value) {
        hasError = true;
        errorMsg = "Start 1 must be smaller than End";
      }
    } else if (field === "start_1") {
      if (params.row.end_1 && params.row.end_1 <= params.props.value) {
        hasError = true;
        errorMsg = "Start 1 must be smaller than End";
      }
      if (params.row.end_2 && params.row.end_2 <= params.props.value) {
        hasError = true;
        errorMsg = "Start 1 must be smaller than End 2";
      }
    } else if (field === "start_2") {
      if (params.row.end_2 && params.row.end_2 <= params.props.value) {
        hasError = true;
        errorMsg = "Start 2 must be higher than End";
      }
      if (params.row.end_1 && params.row.end_1 >= params.props.value) {
        hasError = true;
        errorMsg = "Start 2 must be smaller than End";
      }
    }
  }

  if (params.props.value && !(params.props.value as Moment).isValid()) {
    hasError = true;
  }

  return { ...params.props, error: hasError != null, errorMsg: errorMsg };
}

const defaultColAttributes = {
  renderCell: renderTime,
  renderEditCell: renderTimeEditInputCell,
  valueFormatter: ({ value }) =>
    `${value ? moment(value).format("HH:mm") : ""}`,
  width: 75,
};

import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import CloseIcon from "@mui/icons-material/Close"
import {
  AppBar,
  Button,
  CircularProgress,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Toolbar,
  Typography
} from "@mui/material"

import LoadingSpinner from "../components/LoadingSpinner"
import { BaseService } from "../services/base.service"
import { FormInputDatePicker } from "./form-components/FormInputDatePicker"
import { FormInputDropdown } from "./form-components/FormInputDropdown"
import { FormInputFileUpload } from "./form-components/FormInputFileUpload"
import { FormInputHTMLEditor } from "./form-components/FormInputHTMLEditor"
import { FormInputNumber } from "./form-components/FormInputNumber"
import { FormInputRelatedField } from "./form-components/FormInputRelatedField"
import { FormInputSwitch } from "./form-components/FormInputSwitch"
import { FormInputText } from "./form-components/FormInputText"

export interface ReactiveFormProps {
  ignoredFields: string[];
  baseUrl: string;
  title: string;
  extraParams?: { [key: string]: any };
  initialValues?: { [key: string]: any };
  instance?: any;
  onClose: () => void;
  afterSubmit: (data: any) => void;
}

export default function ReactiveForm(props: ReactiveFormProps) {
  const {
    ignoredFields,
    initialValues,
    instance,
    baseUrl,
    title,
    extraParams,
    onClose,
    afterSubmit,
  } = props;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [formFields, setFormFields] = useState(null);

  const methods = useForm({
    defaultValues: instance ? instance : initialValues,
    shouldUnregister: true,
  });

  const {
    handleSubmit,
    control,
    register,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = methods;

  useEffect(() => {
    const fetchMeta = async () => {
      setIsLoading(true);
      const fields = await new BaseService().getFormMeta(
        baseUrl,
        ignoredFields
      );
      setFormFields(fields);
      setIsLoading(false);
    };

    fetchMeta();
  }, []);

  const onSubmit = async (data: any) => {
    setButtonLoading(true);

    Object.keys(data).forEach((key) => {
      if (data[key] === "") {
        data[key] = null;
      }
    });

    if (extraParams) {
      data = { ...data, ...extraParams };
    }

    try {
      if (instance && instance.id) {
        await new BaseService()
          .updateByUrl(baseUrl, instance.id, data)
          .then((item: any) => afterSubmit(item));
      } else {
        await new BaseService().createFromUrl(baseUrl, data).then((item) => {
          afterSubmit(item);
        });
      }
    } catch (error) {
      setButtonLoading(false);
    }
  };

  const buildFields = (field: any, show: boolean) => {
    let fieldInput: any;

    if (show) {
      const common = {
        field: field,
        key: field.name,
        register: register,
        control: control,
        errors: errors,
        initialValues: getValues(),
        setValue: setValue,
        baseUrl: baseUrl,
      };

      switch (field.type) {
        case "string":
          fieldInput = <FormInputText {...common} />;
          break;
        case "text":
          fieldInput = <FormInputHTMLEditor {...common} />;
          break;
        case "email":
          fieldInput = <FormInputText {...common} type={"email"} />;
          break;
        case "integer":
          fieldInput = <FormInputNumber {...common} type={"number"} />;
          break;
        case "decimal":
          fieldInput = <FormInputNumber {...common} type={"number"} />;
          break;
        case "boolean":
          fieldInput = <FormInputSwitch {...common} hideLabel={true} />;
          break;
        case "url":
          fieldInput = <FormInputText {...common} />;
          break;
        case "date":
          fieldInput = <FormInputDatePicker {...common} hideLabel={true} />;
          break;
        case "choice":
          fieldInput = <FormInputDropdown {...common} />;
          break;
        case "fk":
          fieldInput = <FormInputRelatedField {...common} />;
          break;
        case "m2m":
          fieldInput = <FormInputRelatedField {...common} />;
          break;
        case "image upload":
          fieldInput = <FormInputFileUpload {...common} />;
          break;
        default:
          fieldInput = `Cannot render "${field.name}: ${field.type}"`;
      }

      watch(field.name);
    }

    return fieldInput;
  };

  return (
    <>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {t(title)}
          </Typography>

          {isButtonLoading && (
            <CircularProgress
              size={20}
              sx={{ marginRight: 1, color: "secondary.main" }}
            />
          )}
          {!isButtonLoading && !isLoading && (
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              size="large"
              onClick={handleSubmit(onSubmit)}
              disabled={isButtonLoading}
            >
              {instance ? t("Save") : t("Create")}
            </Button>
          )}
        </Toolbar>
      </AppBar>

      <DialogContent
        sx={{ backgroundColor: (theme) => theme.palette.background.default }}
      >
        {(!formFields || isLoading) && <LoadingSpinner />}
        {formFields && (
          <Grid
            container
            rowSpacing={4}
            spacing={4}
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              padding: {
                xs: 2,
                md: 0,
              },
              paddingX: {
                xs: 0,
                md: 25,
              },
              paddingTop: {
                xs: 0,
                md: 4,
              },
              backgroundColor: (theme) => theme.palette.background.default,
              "& .MuiOutlinedInput-input": {
                backgroundColor: (theme) => theme.palette.background.paper,
              },
              "& .Mui-disabled": {
                backgroundColor: (theme) => theme.palette.background.default,
              },
            }}
          >
            {formFields?.map((field) => {
              let fieldInput: any = "nothing here";
              let show = true;
              const muiMeta = field.meta_extra || {};

              if (field.meta_extra?.show_when) {
                Object.keys(muiMeta.show_when).forEach((key) => {
                  const value = muiMeta.show_when[key];
                  if (!(getValues(key) === value)) {
                    show = false;
                  }
                });
              }

              fieldInput = buildFields(field, show);

              if (fieldInput) {
                return (
                  <Grid
                    item
                    key={field.name}
                    xs={12}
                    md={muiMeta.mui?.xs || 12}
                  >
                    <FormControl fullWidth>
                      {fieldInput}
                      {field.help_text && (
                        <FormHelperText>{field.help_text}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                );
              }

              return "";
            })}
          </Grid>
        )}
      </DialogContent>
    </>
  );
}

import { useContext, useEffect } from "react"
import { Route, Routes } from "react-router-dom"

import { ProtectedRoute } from "../components/ProtectedRoute"
import { GlobalContext } from "../contexts/GlobalContext"
import VitycurryContextProvider from "../contexts/VitycurryContext"
import VitacurryHome from "../pages/VitacurryHome"

function VitacurryApp() {
  const { setCurrentApp } = useContext(GlobalContext);

  useEffect(() => {
    setCurrentApp("vitycurry");

    return;
  }, []);
  return (
    <>
      <VitycurryContextProvider
        children={
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <VitacurryHome />
                </ProtectedRoute>
              }
            />
          </Routes>
        }
      />
    </>
  );
}

export default VitacurryApp;

import React, { useState } from "react"

import { Timesheet } from "../models/Timesheet"

export const TimesheetsContext: any = React.createContext({});

const TimesheetsContextProvider = ({ children }: any) => {
  const [contracts, setContracts] = useState(null);
  const [contract, setContract] = useState(null);
  const [timesheets, setTimesheets] = useState<Timesheet[] | null>(null);
  const [timesheet, setTimesheet] = useState<Timesheet | null>(null);
  const [timesheetEntries, setTimesheetEntries] = useState(null);

  return (
    <TimesheetsContext.Provider
      value={{
        contract,
        setContract,
        contracts,
        setContracts,
        timesheet,
        setTimesheet,
        timesheets,
        setTimesheets,
        timesheetEntries,
        setTimesheetEntries,
      }}
    >
      {children}
    </TimesheetsContext.Provider>
  );
};
export default TimesheetsContextProvider;

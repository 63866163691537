import axios from "axios"
import {
  AlignmentType,
  BorderStyle,
  Document,
  HeadingLevel,
  HeightRule,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  VerticalAlign,
  WidthType
} from "docx"
import { saveAs } from "file-saver"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"

import { Download } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"

import { VitycurryContext } from "../../contexts/VitycurryContext"
import { Profile } from "../../models/Profile"
import { makeExternalProfiles } from "./components/ExternalProfileSection"
import { makePortfolioItems } from "./components/PortfolioItemSection"
import { makeProfile, makeProfileImage } from "./components/ProfileSection"
import { makeSkills } from "./components/SkillsSection"

const borderlessConfig = {
  style: BorderStyle.NONE,
  size: 0,
  color: "eeeeee",
};

const borderless = {
  top: borderlessConfig,
  left: borderlessConfig,
  bottom: borderlessConfig,
  right: borderlessConfig,
  start: borderlessConfig,
  end: borderlessConfig,
};

const styles = {
  default: {
    document: {
      run: {
        font: "Century Gothic",
        size: 9 * 2,
      },
    },
    heading1: {
      run: {
        size: 48 * 2,
      },
      paragraph: {
        spacing: {
          after: 100,
        },
      },
    },
    heading2: {
      run: {
        size: 14 * 2,
      },
      paragraph: {
        spacing: {
          after: 100,
          before: 100,
        },
      },
    },
    heading3: {
      run: {
        size: 14 * 2,
        bold: true,
      },
      paragraph: {
        spacing: {
          before: 250,
          after: 250,
        },
      },
    },
    heading4: {
      run: {
        size: 10 * 2,
        bold: true,
      },
      paragraph: {
        spacing: {
          after: 60,
          before: 200,
        },
      },
    },
    listParagraph: {
      run: {
        size: 9 * 2,
      },
    },
    paragraph: {
      run: {
        size: 9 * 2,
      },
    },
  },
  paragraphStyle: [
    {
      id: "profileImage",
      name: "Profile Image",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      run: {
        alignment: AlignmentType.CENTER,
      },
    },
  ],
};

export default function CVGenerator() {
  const { profiles, externalProfiles, portfolioItems, skills } = useContext(
    VitycurryContext
  ) as any;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const generate = async () => {
    setLoading(true);
    const profile: Profile = profiles[0];

    const image: ArrayBuffer = await fetch(profile.user_photo, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        Origin: window.location.origin,
      },
    }).then((r) => r.arrayBuffer().then((blob) => blob));

    const doc = new Document({
      styles: styles,
      sections: [
        {
          properties: {
            page: {
              margin: {
                top: 100,
                right: 700,
                bottom: 500,
                left: 700,
              },
            },
          },
          children: [
            new Table({
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              borders: borderless,
              rows: [
                new TableRow({
                  height: {
                    value: 3000,
                    rule: HeightRule.EXACT,
                  },
                  children: [
                    new TableCell({
                      borders: borderless,
                      verticalAlign: VerticalAlign.CENTER,
                      width: {
                        size: 30,
                        type: WidthType.PERCENTAGE,
                      },
                      children: [...makeProfileImage(profile, image)],
                    }),
                    new TableCell({
                      borders: borderless,
                      verticalAlign: VerticalAlign.BOTTOM,
                      width: {
                        size: 70,
                        type: WidthType.PERCENTAGE,
                      },
                      children: [
                        new Paragraph({
                          text:
                            profile.first_name || profile.last_name
                              ? `${profile.first_name}${
                                  profile.last_name
                                    ? " " + profile.last_name
                                    : ""
                                }`
                              : "",
                          heading: HeadingLevel.HEADING_1,
                        }),
                        new Paragraph({
                          text: profile.title,
                          heading: HeadingLevel.HEADING_2,
                        }),
                      ],
                    }),
                  ],
                }),
                new TableRow({
                  children: [
                    new TableCell({
                      borders: borderless,
                      width: {
                        size: 30,
                        type: WidthType.PERCENTAGE,
                      },
                      children: [
                        ...makeExternalProfiles(
                          externalProfiles,
                          t("Web profiles")
                        ),
                        ...makeProfile(profile, t("Contact")),
                        ...makeSkills(skills, t("Skills")),
                      ],
                    }),
                    new TableCell({
                      borders: borderless,
                      width: {
                        size: 70,
                        type: WidthType.PERCENTAGE,
                      },
                      children: [
                        ...makePortfolioItems(
                          portfolioItems,
                          t("Portfolio"),
                          t("today")
                        ),
                      ],
                    }),
                  ],
                }),
              ],
            }),
          ],
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      const fileName = new Date()
        .toISOString()
        .slice(0, 19)
        .replace(/-/g, "_")
        .replace(/:/g, "_")
        .replace(/T/g, "_");
      saveAs(blob, `${fileName}.docx`);
      setLoading(false);
    });
  };

  return (
    <LoadingButton
      loadingPosition="start"
      startIcon={<Download />}
      variant="outlined"
      loading={loading}
      onClick={generate}
      size="large"
      fullWidth
    >
      {t("Export .docx")}
    </LoadingButton>
  );
}

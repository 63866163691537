import moment from "moment"
import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"

import { Card, CardContent, CardHeader } from "@mui/material"

import { VitycurryContext } from "../contexts/VitycurryContext"
import { PortfolioItem } from "../models/PortfolioItem"
import portfilioService from "../services/portfolio.service"
import ActionsDropdown from "./ActionsDropdown"
import AddIcon from "./AddIcon"
import EmptyListItem from "./EmptyListItem"
import FormPopUp from "./FormPopUp"
import LoadingSpinner from "./LoadingSpinner"

export default function PortfolioItemsList() {
  const { t } = useTranslation();
  const { portfolioItems, setPortfolioItems } = useContext(
    VitycurryContext
  ) as any;

  const formDefault: PortfolioItem = {
    title: "",
    description: "",
    start_date_month: "",
    start_date_year: "",
    end_date_month: "",
    end_date_year: "",
    currently_works_here: true,
    employment_type: "",
    location: "",
    company: "",
  };
  const ignoredFields = ["id", "created", "modified", "profile", "skills"];

  useEffect(() => {
    async function fetchPortfolioItems() {
      const response = await portfilioService.list();
      setPortfolioItems(response.results);
    }

    fetchPortfolioItems();
    return;
  }, []);

  const momentDate = (portfolioItem: any) => {
    let date = moment(
      `${portfolioItem.start_date_year}-${portfolioItem.start_date_month}`
    ).format("MMMM YYYY");

    if (portfolioItem.end_date_month) {
      date +=
        " - " +
        moment(
          `${portfolioItem.end_date_year}-${portfolioItem.end_date_month}`
        ).format("MMMM YYYY");
    } else if (portfolioItem.currently_works_here) {
      date += " - " + t("today");
    }

    return date;
  };

  if (portfolioItems == null) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {!portfolioItems && <LoadingSpinner />}

      <Card variant="outlined">
        <CardHeader
          title="Portfolio"
          sx={{ paddingBottom: 0 }}
          action={
            <FormPopUp
              title={"Add"}
              btnText={"Add"}
              buttonType={"card-action"}
              initialValues={formDefault}
              setInstanceList={setPortfolioItems}
              instanceList={portfolioItems}
              icon={<AddIcon />}
              baseUrl={portfilioService.BASE_PATH}
              ignoredFields={ignoredFields}
            />
          }
        />
        {portfolioItems.length == 0 && (
          <CardContent>
            <EmptyListItem />
          </CardContent>
        )}

        <CardContent>
          {portfolioItems.map((portfolioItem) => (
            <Card
              variant="outlined"
              sx={{ mb: 1, p: 0 }}
              key={portfolioItem.id}
            >
              <CardHeader
                action={
                  <ActionsDropdown
                    popUpTitle={t("Edit")}
                    instance={portfolioItem}
                    instanceList={portfolioItems}
                    setInstanceList={setPortfolioItems}
                    deletable={true}
                    editable={true}
                    baseUrl={portfilioService.BASE_PATH}
                    ignoredFields={ignoredFields}
                  />
                }
                title={`${
                  portfolioItem.company ? portfolioItem.company + ", " : ""
                }${portfolioItem.title}`}
                subheader={momentDate(portfolioItem)}
                sx={{ pb: 0 }}
              />

              <CardContent
                sx={{ "&:last-child": { pb: 0 }, py: 0, "&ul": { p: 0, m: 0 } }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: portfolioItem.description,
                  }}
                ></div>
              </CardContent>
            </Card>
          ))}
        </CardContent>
      </Card>
    </>
  );
}

import React from "react"
import { useTranslation } from "react-i18next"

import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material"
import { Typography } from "@mui/material"

import timesheetService from "../services/timesheet.service"
import LoadingSpinner from "./LoadingSpinner"

export function TimesheetEntriesCurrentMonth() {
  const [totalHours, setTotalHours] = React.useState(null);
  const { t } = useTranslation();

  React.useEffect(() => {
    const fetchTimesheets = async () => {
      setTotalHours(await timesheetService.analyticsCurrentMonth());
    };

    fetchTimesheets();

    return () => {
      setTotalHours(null);
    };
  }, []);

  return (
    <>
      {totalHours && (
        <>
          <Typography
            variant="h2"
            component="h2"
            sx={{ mb: 2, textAlign: "center" }}
          >
            {totalHours.total_hours_current_month[0] || 0}
          </Typography>
          <Typography component="p" sx={{ textAlign: "center" }}>
            {t("hours")}
          </Typography>
          <Typography
            component="p"
            sx={{ fontSize: "12px", textAlign: "center" }}
          >
            {t("this month")}
          </Typography>
          <Typography
            component="p"
            sx={{ fontSize: "12px", textAlign: "center", mt: 2 }}
          >
            {totalHours.total_hours_last_month[0] || 0} {t("last month")}
          </Typography>
          <Typography
            component="p"
            sx={{
              fontSize: "16px",
              textAlign: "center",
              color:
                totalHours.total_hours_current_month_percentage > 0
                  ? "green"
                  : "red",
            }}
          >
            {totalHours.total_hours_current_month_percentage > 0 ? (
              <ArrowDropUp sx={{ color: "green" }} />
            ) : (
              <ArrowDropDown sx={{ color: "red" }} />
            )}
            {totalHours.total_hours_current_month_percentage && (
              <>{totalHours.total_hours_current_month_percentage}%</>
            )}
          </Typography>
        </>
      )}
      {!totalHours && <LoadingSpinner />}
    </>
  );
}

import React from "react"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Switch
} from "@mui/material"

import { FormInputProps } from "./FormInputProps"

export const FormInputSwitch: React.FC<FormInputProps> = ({
  field,
  control,
  register,
  errors,
  hideLabel,
}) => {
  const { t } = useTranslation();
  const { name, label, required } = field;

  return (
    <FormControl variant={"outlined"}>
      {!hideLabel && <FormLabel component="legend">{label}</FormLabel>}

      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState,
        }) => (
          <>
            <FormControlLabel
              control={
                <Switch
                  checked={value}
                  onChange={onChange}
                  inputProps={register(name, {
                    required: error?.message,
                  })}
                  required={required}
                  {...register(name, { required: required })}
                />
              }
              label={label}
            />
            {errors[name] && (
              <FormHelperText error={true}>{t("Required")}</FormHelperText>
            )}
          </>
        )}
      />
    </FormControl>
  );
};

import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { WarningAmber } from "@mui/icons-material"
import { Grid, LinearProgress, Stack, styled, Typography } from "@mui/material"
import { Box } from "@mui/system"

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 5,
}));
export default function ContractProgressBar(props: any) {
  const { contract, showRevenue } = props;
  const { t } = useTranslation();

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const value =
      ((contract.manual_hours || contract.totals.hours) / contract.hours_max) *
      100;
    setProgress(value);
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BorderLinearProgress
            variant="determinate"
            color={
              !contract.hours_max ||
              (progress < 100 && !contract.manual_complete)
                ? "primary"
                : "success"
            }
            value={!contract.hours_max ? 100 : progress}
          />

          {contract.hours_max > 0 && (
            <Stack sx={{ mt: 1 }}>
              <Typography variant="body1" color={"primary"}>
                {Math.round((contract.totals.hours / contract.hours_max) * 100)}
                {"%: "}
                {contract.hours_max}
                {" - "}
                {contract.totals.hours}
                {" = "}
                {contract.hours_max - contract.totals.hours} {t("remaining")}
              </Typography>

              {showRevenue && (
                <Typography variant="body2" color={"primary"}>
                  {t("Revenue")}:{" "}
                  {Math.round(
                    contract.totals.hours * contract.hourly_rate
                  ).toLocaleString()}
                  {"/"}
                  {Math.round(
                    contract.hours_max * contract.hourly_rate
                  ).toLocaleString()}{" "}
                  {contract.currency || ""}
                </Typography>
              )}

              {contract.end_date &&
                !contract.manual_complete &&
                new Date(contract.end_date) < new Date() &&
                contract.hours_max >
                  (contract.manual_hours || contract.totals.hours) && (
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <WarningAmber fontSize="small" color="error" />
                    <Typography
                      variant="body1"
                      fontSize={"medium"}
                      color={"error"}
                    >
                      {t("Contract expired")}
                    </Typography>
                  </Stack>
                )}
            </Stack>
          )}
        </Grid>
      </Grid>
    </>
  );
}

import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function NoResults() {
  const { t } = useTranslation();

  return (
    <Alert severity="info" sx={{ width: "100%" }}>
      {t("No records found")}
    </Alert>
  );
}
